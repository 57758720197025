import React from 'react';
import { Box, Typography } from '@mui/material';
import TileButton from '../../components/TileButton';
import useDashboard from './admin-dashboard-hook';
import Loader from '../../components/Loader/loader';
import StateIcon from '../../components/Icons/StateIcon';
import DistrictsIcon from '../../components/Icons/DistrictsIcon';
import SchoolsIcon from '../../components/Icons/SchoolsIcon';
import ClassesIcon from '../../components/Icons/ClassesIcon';
import { StudentsIcon } from '../../components/Icons';
import TeachersIcon from '../../components/Icons/TeachersIcon';
import SchoolAdminIcon from '../../components/Icons/SchoolAdminIcon';

const AdminDashboard = () => {
  const {
    dataLoading: loading,
    dashboardData: data,
    districtPermission,
    districtAdminPermission,
    schoolPermission,
    schoolAdminPermission,
    teacherPermission,
    classPermission,
    studentPermission,
  } = useDashboard();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        <Typography variant="pageTitle">
          Dashboard
        </Typography>
      </Box>
      <Box
        mt={3}
        display="grid"
        gridTemplateColumns="repeat(4, 250px)"
        sx={{
          gridGap: 48,
        }}
      >
        {districtPermission && (
          <>
            <TileButton
              label="States"
              count={data?.states}
              Icon={StateIcon}
            />
            <TileButton
              label="Districts"
              count={data?.districts}
              Icon={DistrictsIcon}
              redirectionLink="/districts"
            />
          </>
        )}
        {schoolPermission && (
          <TileButton
            label="Schools"
            count={data?.schools}
            Icon={SchoolsIcon}
            redirectionLink="/schools"
          />
        )}
        {classPermission && (
          <TileButton
            label="Classes"
            count={data?.classes}
            Icon={ClassesIcon}
            redirectionLink="/classes"
          />
        )}
        {studentPermission && (
          <TileButton
            label="Students"
            count={data?.students}
            Icon={StudentsIcon}
            redirectionLink="/admin-students"
          />
        )}
        {teacherPermission && (
          <TileButton
            label="Teachers"
            count={data?.teachers}
            Icon={TeachersIcon}
            redirectionLink="/teachers"
          />
        )}
        {schoolAdminPermission && (
          <TileButton
            label="School Admins"
            count={data?.school_admins}
            Icon={SchoolAdminIcon}
            redirectionLink="/admins/school"
          />
        )}
        {districtAdminPermission && (
          <TileButton
            label="District Admins"
            count={data?.district_admins}
            Icon={SchoolAdminIcon}
            redirectionLink="/admins/district"
          />
        )}
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(AdminDashboard);
