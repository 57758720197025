import React from 'react';
import { Box, Checkbox, CheckboxProps, InputAdornment, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import TeachersIcon from '../Icons/TeachersIcon';
import { Maybe, TeacherFilterFragmentFragment } from '../../generated/graphql';
import useTeachersFilter from '../TeacherFilter/teachers-filter-hook';

interface Props {
  filterLabel?: string;
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (data: Maybe<string>[] | undefined) => void;
  stateId?: string | undefined;
  districtId?: string | undefined;
  schoolId?: string | undefined;
  disabled?: boolean;
  teacherIcon?: boolean;
  limitTags?: number;
  textInputPlaceholder?: string;
  onSelectedTeachersChange?: (selectedTeachers: TeacherFilterFragmentFragment[]) => void;
  schoolsInScope?: Maybe<string>[];
  updateLoader?: Function;
  cleverMappedTeachers?: string[]
}

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  '& input.MuiInputBase-input.Mui-disabled' : {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

type TeacherValue = TeacherFilterFragmentFragment | null | undefined;

const TeacherSelectMultiple = ({
  filterLabel = 'Secondary Teacher',
  className,
  onChange,
  value,
  stateId,
  districtId,
  schoolId,
  disabled,
  limitTags = 3,
  textInputPlaceholder,
  onSelectedTeachersChange,
  schoolsInScope,
  updateLoader,
  cleverMappedTeachers = []
}: Props) => {
  const {
    teachers,
    loading,
  } = useTeachersFilter({ stateId, districtId, schoolId });
  updateLoader?.(loading);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_:any, teacherList:(any)[]) => {
    onChange?.(teacherList?.map((teacher:any) => teacher?.id));
    onSelectedTeachersChange?.(teacherList);
  };
  const teacherOptions = (!schoolsInScope || !schoolsInScope.length) ? teachers : teachers.filter(
    (teacher) => teacher?.school?.map(sch => sch?.id).some(item => schoolsInScope.includes(item!)),
  );

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      className={className}
      disableCloseOnSelect
      options={teacherOptions}
      getOptionDisabled={(option: any) => cleverMappedTeachers?.includes(option.id)}
      getOptionLabel={(option: TeacherValue) => `${option?.first_name ?? ''} ${option?.last_name ?? ''}`}
      isOptionEqualToValue={(option: TeacherValue, selected: any) => option?.id === selected?.id}
      disabled={loading || disabled}
      disableClearable={cleverMappedTeachers?.length ? true : false}
      onChange={onSelectChange}
      value={teacherOptions?.filter((teacher) => value?.includes(teacher?.id))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.first_name ?? ''} {option?.last_name ?? ''}</Typography>}
            {...getTagProps({ index })}
            disabled={cleverMappedTeachers?.includes(option?.id)}
          />
        ))
      }
      renderOption={(props, option: TeacherValue, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
            disabled={cleverMappedTeachers?.includes(option?.id ?? '')}

          />
          {`${option?.first_name ?? ''} ${option?.last_name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {filterLabel && <Typography variant='sFormTitle' component='p'>{filterLabel}</Typography>}
          <TextField
            {...params}
            sx={{ ...styles }}
            placeholder={params.InputProps.startAdornment ? undefined : (textInputPlaceholder ? textInputPlaceholder : 'None')}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px' },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <TeachersIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default React.memo(TeacherSelectMultiple);
