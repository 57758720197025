import { useEffect, useRef } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  OrderBy,
  useAddTypeformUserMappingMutation,
  useAssignmentsQuery,
  useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { AssignmentSort, AssignmentTabs, TeacherBenchmarksDashboardTabs } from '../../../variables/types';
import { toUTC } from '../../../utils/dateFormat';
import usePrevious from '../../../utils/usePrevious';
import { useHistory } from 'react-router-dom';
import RoutingPaths from '../../RoutingPath';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import { trackEvent } from '../../../utils/trackevent';

interface Params {
  section?: string;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const getDateFilter = (section: string = AssignmentTabs.Current, dateString: string) => {
  let start = null;
  let close = null;
  if (section === AssignmentTabs.Past) {
    close = {
      lt: dateString,
    };
  } else if (section === AssignmentTabs.Upcoming) {
    start = {
      gt: dateString,
    };
  } else {
    close = {
      gte: dateString,
    };
    start = {
      lte: dateString,
    };
  }
  return {
    closeAt: close,
    startAt: start,
  };
};

const useAssignments = ({ section }: Params) => {
  const pagination = usePagination();
  const now = useRef(toUTC(new Date()));
  const prevSection = usePrevious(section);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, AssignmentSort.CloseAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const { startAt, closeAt } = getDateFilter(section, now.current);
  const history = useHistory();
  const { data, loading, refetch: assignmentsRefetch } = useAssignmentsQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    variables: {
      limit: PAGE_SIZE,
      startAt,
      closeAt,
      page: pagination.page,
      sort,
      orderBy: order as OrderBy,
    },
  });
  
  const [typeformSurvey, setTypeformSurvey] = useLocalStorageState('TBD-typeform-survey', 0);

  const { data: teacherBenchmark, loading: teacherBenchmarkLoading } = useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();


  const onSortChange = (sortBy: AssignmentSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  useEffect(() => {
    if (prevSection && section !== prevSection) {
      pagination.setPage(1);
    }
  }, [pagination, prevSection, section]);

  const assignments = data?.getAllAssignmentsForTeacher.nodes ?? [];
  const totalCount = data?.getAllAssignmentsForTeacher.totalCount ?? 0;

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: teacherBenchmark?.getTeacherBenchmarkDashboardWalkthrough.id ?? '',
          submitted: true,
        },
      });
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const onTypeformSubmit = async () => {
    trackEvent('tbm_typeform', 'teacher_benchmark_dashboard_typeform_submit');
    setTypeformSurvey(typeformSurvey as number + 1);
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: teacherBenchmark?.getTeacherBenchmarkDashboardWalkthrough.id ?? '',
          submitted: true,
        },
      });
      history.push(`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}`);
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  return {
    loading : loading || teacherBenchmarkLoading,
    totalCount,
    assignments, assignmentsRefetch,
    pagination,
    onSortChange,
    sort,
    order: order as OrderBy,
    onTypeformReady,
    onTypeformSubmit,
    updateUserLoading,
    typeformData: teacherBenchmark?.getTeacherBenchmarkDashboardWalkthrough ?? null,
  };
};

export default useAssignments;
