// TO DO: simplify the routes and declare all static at one place
export const districtsRoute = {
  label: 'Districts',
  route: '/districts',
};

export const schoolsRoute = {
  label: 'Schools',
  route: '/schools',
};

export const teachersRoute = {
  label: 'Teachers',
  route: '/teachers',
};

export const sectionsRoute = {
  label: 'Classes',
  route: '/classes',
};

export const studentsRoute = {
  label: 'Students',
  route: '/admin-students',
};

export const adminsRoute = (type: string) => {
  return {
    label: 'Admins',
    route: `/admins/${type}`,
  };
};

export const generateCreateOrEditRoute = (name: string, type: string, id?: string) => {
  return {
    label: `${id ? 'Edit' : 'Create'} ${name}`,
    route: id ? `/admin/create/${type}/${id}` : `/admin/create/${type}`,
  };
};

export const adminToolsRoute = {
  label: 'Admin Tools',
  route: '/settings',
};
export const superAdminRoute = {
  label: 'Super Admins',
  route: '/settings/super-admin',
};

