// eslint-disable-next-line import/prefer-default-export

import { GoalAnalyticsData, Maybe } from '../generated/graphql';

export enum NotifierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export type Noop = () => void;

export type OpenSnackbarFunction = (
  notifierProp: NotifierProps,
  variant: NotifierType
) => void;
export interface NotifierProps {
  message: string | string[];
  messageTitle?: string;
  customIcon?: any;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
}

export enum TableColumnSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type User = {
  role: String
  id: String
};

export type ScoreScheme = {
  score_value: number,
  color_code: string
};

export enum TaskStatus {
  New = 'new',
  ClickAndLabel = 'ClickAndLabel',
  Speaking = 'Speaking',
  Writing = 'Writing',
  ScoringNew = 'ScoringNew',
  ScoringComplete = 'ScoringComplete',
}

export enum SubmissionStatus {
  Started = 'Started',
  AnnotationCompleted = 'AnnotationCompleted',
  SpeakingCompleted = 'SpeakingCompleted',
  Submitted = 'Submitted',
  Reviewed = 'Reviewed',
}

export enum ReadableStatus {
  Started = 'Labeling',
  AnnotationCompleted = 'Recording',
  SpeakingCompleted = 'Writing',
  Submitted = 'Not scored',
  Reviewed = 'Scored',
}

export enum ScoreTabs {
  Speaking = 'Speaking',
  Writing = 'Writing',
  Goals = 'Goals',
}

export enum AssignmentTabs {
  Past = 'past',
  Current = 'current',
  Upcoming = 'upcoming',
}

export enum StudentTabs {
  Tasks = 'Task',
  Goal = 'Goal',
}

export enum PTSubmissionSteps {
  Labeling = 'Labeling',
  Speaking = 'Speaking',
  Writing = 'Writing',
}

export enum NavigationType {
  Top = 'top',
  None = 'none',
  Side = 'side',
  Both = 'both',
}

export enum AudioState {
  Stop = 'stop',
  Play = 'play',
}

export enum GoalsStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  NotStarted = 'Not Started',
}
export interface AnnotationInterface {
  id?: string,
  submission_id?: string,
  x_position: number,
  y_position: number,
  title: string,
}

export interface AssignmentPageParams {
  section?: string;
  id?: string;
}

export enum SubmissionForTeachersSort {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  DateAsc = 'DateAsc',
  DateDesc = 'DateDesc',
}

export enum SubmissionFilter {
  Scored = 'scored',
  NotScored = 'not-scored',
  AllSubmissions = 'all',
}

export enum SubmissionSort {
  Name = 'first_name',
  Date = 'submitted_at',
  Assignment = 'title',
}

export enum AssignmentSort {
  Title = 'title',
  StartAt = 'start_at',
  CloseAt = 'close_at',
}

export enum DistrictSort {
  Name = 'name',
  DistrictId = 'district_id',
  StudentCount = 'student_count',
  SchoolCount = 'school_count',
  State = 'state_id',
  Source = 'source',
}

export enum SuperAdminSort {
  FirstName = 'first_name',
  JobTitle = 'job_title',
  Email = 'email',
  UpdatedAt = 'updated_at',
  UpdatedByFirstName = 'updated_by',
  Source = 'source',
}

export enum SchoolSort {
  Name = 'name',
  SchoolId = 'school_id',
  District = 'district_name',
  StudentCount = 'student_count',
  Source = 'district_source',
}

export enum ClassesSort {
  Name = 'name',
  Grade = 'grade',
  Periods = 'period',
  School = 'school',
  District = 'district',
}

export enum AdminListSort {
  JobTitle = 'job_title',
  AdminId = 'admin_id',
  District = 'district',
  AdminFirstName = 'first_name',
  Source = 'source',
  School = 'school',
}

export enum AdminStudentsSort {
  StudentID = 'student_id',
  FirstName = 'name',
  School = 'school',
  Grade = 'grades',
  District = 'district',
  Source = 'source',
}

export enum TeachersSort {
  JobTitle = 'job_title',
  TeacherId = 'teacher_id',
  TeacherFirstName = 'name',
  District = 'district',
  School = 'school',
  Source = 'source',
}

export interface PaginationProps {
  page: number;
  setPage: (nextPage: number) => void;
  resetPage: Function,
  unsetPage: Function,
}

export interface AudioFileType {
  url: string,
  fileType: string,
}

export enum StudentSort {
  FirstName = 'first_name',
  StudentID = 'student_number',
  Grade = 'grade',
  LanguageClass = 'language_class',
  LanguageLevel = 'language_level',
  HeritageLanguage = 'heritage_language',
  Source = 'source',
}

export enum ModalTypes {
  Fullscreen = 'fullscreen',
  Drawer = 'drawer',
}

export type ButtonVariant = 'text' | 'outlined' | 'contained';
export type ButtonType = 'default' | 'danger';

export enum AnalyticsTabs {
  Overview = 'overview',
  Speaking = 'speaking',
  Writing = 'writing',
  SpeakingGoals = 'speaking-goals',
  WritingGoals = 'writing-goals',
}

export enum Skills {
  Speaking = 'speaking',
  Writing = 'writing',
}

export enum SpeakingSkills {
  Grammar = 'grammar',
  Vocabulary = 'vocabulary',
  Pronunciation = 'pronunciation',
  Interpretation = 'interpretation',
  Fluency = 'fluency',
}

export enum WritingSkills {
  VocabAndGrammar = 'vocab_and_grammar',
  PointsAndReason = 'points_and_reasons',
  DescriptionAndExplanation = 'description_and_explanation',
}

export enum GoalStatuses {
  NoAttempt = 'NoAttempt',
  Emerging = 'Emerging',
  Progressing = 'Progressing',
  GoalMet = 'GoalMet',
}

export enum RubricTitles {
  Grammar = 'Grammar',
  Vocabulary = 'Vocabulary',
  Pronunciation = 'Pronunciation',
  Interpretation = 'Interpretation',
  Fluency = 'Fluency',
  VocabularyAndGrammar = 'Vocabulary and Grammar',
  PointsAndReason = 'Points and Reasons',
  DescriptionAndExplanation = 'Description/Explanation',
}

export enum TeacherBenchmarksDashboardTabs {
  BenchmarkSummary = 'benchmark-summary',
  ProgressScores = 'progress-scores',
}

export interface Student {
  student_id: string,
  student_name: string,
}

export interface GraphData {
  skillLevel: number,
  skillLabel: string,
  skillDescription: string,
  skillLinkText: string,
  color: string,
  numberOfStudents: number,
  skillType: string,
  students: Student[],
  skillKey?: string,
}

export interface GoalGraphData {
  skillLabel: string,
  skillLinkText: string,
  color: string,
  numberOfGoals: number,
  skillType: string,
  skillKey?: string,
  goals?: Array<Maybe<Goals>>,
}

export type Goals = GoalAnalyticsData & { skillKey: string };

export enum AdminType {
  School = 'school',
  District = 'district',
}

export interface EventData {
  pageX: number,
  pageY: number
}

export interface TooltipState {
  showTooltip: boolean,
  event: EventData | null,
  tooltipData: any
}

export enum ChipTypes {
  default = 'default',
  outlined = 'outlined',
}

export type AxisDomainItem = string | number | Function | 'auto' | 'dataMin' | 'dataMax';
export type AxisDomain = string[] | number[] | [AxisDomainItem, AxisDomainItem];

export enum Entity {
  District = 'district',
  School = 'school',
  Class = 'class',
  Teacher = 'teacher',
  Student = 'student',
  SchoolAdmin = 'school-admin',
  DistrictAdmin = 'district-admin',
  CleverDistrict = 'clever-district',
}

export enum SyncStatus {
  NotSynced = 'not-synced',
  Synced = 'synced',
  Syncing = 'syncing',
  SyncError = 'sync-error',
}

export interface GoalSetGraphData {
  color: string,
  skill_description: string,
  id: string,
  numberOfGoals: number,
  skill_label: string,
  skillType: string,
  skill_key:string,
  linkText?: string
  goals?: Array<Maybe<GoalsSet>>,
  students?: [],
  skillLabel?: string,
}

export interface GraphSetData {
  color: string,
  skill_description: string,
  id: string,
  numberOfStudents: number,
  skill_label: string,
  skillType: string,
  students: Student[],
  skill_key?: string,
  skill_level?: number,
  total_count?: number,
  iconString?: string,
}
export enum ScoreType {
  limited = 'limited',
  emerging = 'emerging',
  expanding = 'expanding',
  bridging = 'bridging',
  advanced = 'advanced',
  texasAdvanced = 'texas_advanced',
  beginner = 'beginning',
  intermediate = 'intermediate',
  advancedHigh = 'advanced_high',
}

export type GoalsSet = GoalAnalyticsData & { skill_key: string };

export enum ScoreSchemeTitleKeys {
  Scheme1 = 'scheme_1',
  DefaultScheme = 'default_scheme',
}

export enum SubmissionTypes {
  Speaking = 'Speaking',
  Writing = 'Writing',
}
export enum BenchmarkType {
  BOY = 'BOY',
  MOY = 'MOY',
  EOY = 'EOY',
}

export interface RubricScoreDetail {
  rubric_id: string
  rubric_title: string
  rubric_icon_base_64: string
  score_detail_id: string
  score_detail_title: string
  score_detail_description: string
  score_detail_color_code: string
  count: number
}

export enum DistrictSyncSort {
  CreatedAt = 'created_at',
  RunDuration = 'run_duration',
}

export type FormStep = {
  step: number,
  name: string,
  label: string,
};

export enum DistrictWideComparisonsTabs {
  Schools = 'schools',
  Grades = 'grades',
}

export enum AdminAnalyticsFilterTypes {
  Schools = 'Schools',
  Grades = 'Grades',
  Teachers = 'Teachers',
  Classes = 'Classes',
  DateRange = 'DateRange',
}

export enum AdminAnalyticsDateFilterOptions {
  Last30Days = 'Last 30 Days',
  ThisMonth = 'This Month',
  Last3Months = 'Last 3 Months',
  // LastYear = 'Last Year',
  CustomDateRange = 'Custom Date Range',
}

export const goalScoreOptions = [
  {
    id: '1',
    label: 'No Attempt',
    score: 1,
    value: GoalStatuses.NoAttempt,
  },
  {
    id: '2',
    label: 'Emerging',
    score: 2,
    value: GoalStatuses.Emerging,
  },
  {
    id: '3',
    label: 'Progressing',
    score: 3,
    value: GoalStatuses.Progressing,
  },
  {
    id: '4',
    label: 'Goal Met',
    score: 4,
    value: GoalStatuses.GoalMet,
  },
];

export enum GradesSort {
  StudentName = 'student_name',
  BenchmarkTitle = 'benchmark_title',
  Grade = 'grade_title',
  District = 'district_title',
  SubmittedAt = 'submitted_at',
}

export enum SizeMetrics {
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
}

export enum GradedFilter {
  Graded = 'Reviewed',
  NotGraded = 'Submitted',
  AllSubmissions = 'all',
}

export enum ImitatationUserType {
  Teacher = 'Teacher',
  Student = 'Student',
  DistrictAdmin = 'DistrictAdmin',
  SchoolAdmin = 'SchoolAdmin',
}

export enum TabsHeadings {
  Schools = 'Schools',
  Classes = 'Classes',
  Teachers = 'Teachers',
  Students = 'Students',
  SchoolAdmins = 'School Admins',
  DistrictAdmins = 'District Admins',
  Syncs = 'Syncs',
  Groups = 'Groups',
}

export enum TabRouteEntities {
  Districts = 'districts',
  Schools = 'schools',
  Classes = 'classes',
  Teachers = 'teachers',
  Students = 'admin-students',
  SchoolAdmins = 'admins/school',
  DistrictAdmins = 'admins/district',
  Syncs = 'syncs',
  Groups = 'groups',
}

export enum GroupsSort {
  Name = 'name',
  StudentCount = 'student_count',
}
