import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssignmentsTable from './AssignmentsTable';
import CreateAssignment from './CreateAssignment';
import { AssignmentTabs } from '../../variables/types';
import RoutingPaths from '../RoutingPath';

const Assignments = () => (
  <>
    <Switch>
      <Route
        path={RoutingPaths.CreateAssignment}
        component={CreateAssignment}
        exact
      />
      <Route
        path={`${RoutingPaths.CreateAssignment}/:step`}
        component={CreateAssignment}
        exact
      />
      <Route
        path={RoutingPaths.EditAssignment}
        component={CreateAssignment}
        exact
      />
      <Route
        path={`${RoutingPaths.EditAssignment}/:step`}
        component={CreateAssignment}
        exact
      />
      <Route
        path={RoutingPaths.AssignmentsTable}
        component={AssignmentsTable}
        exact
      />
      <Redirect
        from={RoutingPaths.Assignments}
        to={`/assignments/${AssignmentTabs.Current}`}
      />
    </Switch>
  </>
);

export default React.memo(Assignments);
