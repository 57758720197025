import { useEffect, useState } from 'react';
import { pieChartData } from '../overallperformance.hooks';
import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import {
  BenchmarkType,
  OrderBy,
  OverallPerformanceLevelSlideOutInput,
  OverallPerformanceLevelSlideOutLineItem,
  OverallPerformanceLevelSlideOutSortType,
  SubmissionType,
  useOverallPerformanceLevelSlideOutQuery,
} from '../../../../../generated/graphql';
import { trackEvent } from '../../../../../utils/trackevent';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,
  submissionType?: string,
};
const usePerformanceLevelSlideOut = ({ data, benchmarkType, submissionType }: Props) => {
  const [selectedOption, setSelectedOption] = useState(data?.scoreLevelId || 'all');
  const [order, setOrder] = useState(OrderBy.Asc);
  const [sort, setSort] = useState<OverallPerformanceLevelSlideOutSortType>(OverallPerformanceLevelSlideOutSortType.StudentFullName);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const handleChange = (event: any) => {
    const label = (event.target.labels[0].innerText).toLowerCase();
    trackEvent(`rb_${submissionType?.toLowerCase()}_pl_${label}`, `radio_buttons_${submissionType?.toLowerCase()}_performace_level_${label}_click`);
    setSelectedOption(event.target.value); 
  };
  const [slideOutData, setSlideOutData] = useState<OverallPerformanceLevelSlideOutLineItem[] | null>();
  const { selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest } = useTeacherBenchmarksDashboardContext();
  var queryInput: OverallPerformanceLevelSlideOutInput = {
    benchmark_type: benchmarkType!,
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    score_level_id: selectedOption === 'all' ? undefined : selectedOption,
    sort_by: sort,
    order_by: order as OrderBy,
    submission_type: submissionType as SubmissionType,
  };
  const { data: overallPerformanceLevelSlideOutData, loading: overallPerformanceLevelSlideOutDataLoading, refetch: refetchOverallPerfomanceLevelSlideOutData } = useOverallPerformanceLevelSlideOutQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });
  useEffect(() => {
    refetchOverallPerfomanceLevelSlideOutData();
  }, [selectedOption, order, sort]);

  useEffect(() => {
    setSlideOutData(overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut);
    setIsTableEmpty(overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut?.length === 0);
  }, [overallPerformanceLevelSlideOutData]);


  const onSortChange = (sortBy: OverallPerformanceLevelSlideOutSortType, orderBy: OrderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: OverallPerformanceLevelSlideOutSortType) => () => {
    if (column === queryInput.sort_by) {
      onSortChange(column, queryInput.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  return {
    selectedOption,
    setSelectedOption,
    handleChange,
    order, setOrder,
    overallPerformanceLevelSlideOutData,
    overallPerformanceLevelSlideOutDataLoading,
    slideOutData,
    isTableEmpty,
    sort,
    handleSort,
  };
};
export default usePerformanceLevelSlideOut;