
import { useCallback, useEffect, useState } from 'react';

interface SearchParams {
  searchSegment: string
  placeholder: string
}

const searchData: SearchParams[] = [
  { searchSegment: '/schools', placeholder: 'Search all schools' },
  { searchSegment: '/districts', placeholder: 'Search all districts' },
  { searchSegment: '/teachers', placeholder: 'Search all teachers' },
  { searchSegment: '/classes', placeholder: 'Search all classes' },
  { searchSegment: '/admins/district', placeholder: 'Search all district admins' },
  { searchSegment: '/district-admin', placeholder: 'Search all district admins' },
  { searchSegment: '/admin-students', placeholder: 'Search all students' },
  { searchSegment: '/admins/school', placeholder: 'Search all school admins' },
  { searchSegment: '/benchmarks/grade', placeholder: 'Search all benchmark grades' },
  { searchSegment: '/benchmarks', placeholder: 'Search all benchmarks' },
  { searchSegment: '/school-admin', placeholder: 'Search all school admins' },
  { searchSegment: '/students', placeholder: 'Search all students' },
  { searchSegment: '/benchmarks', placeholder: 'Search all benchmarks' },
  { searchSegment: '/benchmarks/grade', placeholder: 'Search all benchmarks grades' },
  { searchSegment: '/syncs', placeholder: 'Search syncs' },
  { searchSegment: '/groups', placeholder: 'Search all groups' },
  { searchSegment: '/settings/super-admin', placeholder: 'Search all super admins' },
  { searchSegment: '/syncDistricts', placeholder: 'Search Syncs' },
];

const useSearch = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [placeholder, setPlaceholder] = useState<string>('Search');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchSegment, setSegment] = useState<string>();
  const [tabSection, setTabSection] = useState<string>();

  useEffect(() => { setSearchTerm(undefined); }, [placeholder]);

  const setSearchEnabled = useCallback(() => {
    const { location } = window;
    const pathName = location.pathname;
    if (searchSegment !== pathName) {
      setSearchTerm(undefined);
    }
    setSegment(location.pathname);
    const lastElementOfURL = `/${tabSection}`;
    let searchSegmentD = searchData.find((segment) => segment.searchSegment === lastElementOfURL);
    if (!searchSegmentD) {
      searchSegmentD = searchData.find((segment) => segment.searchSegment === location.pathname);
    }
    const isEnabled = location.pathname === searchSegmentD?.searchSegment || lastElementOfURL === searchSegmentD?.searchSegment;
    setIsSearchEnabled(isEnabled);
    setPlaceholder(searchSegmentD ? searchSegmentD?.placeholder : 'Search');
  }, [searchSegment, tabSection]);


  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm(undefined);
    }
  };

  return {
    searchTerm,
    isSearchEnabled,
    searchSegment,
    placeholder,
    onSearch,
    setSearchEnabled,
    tabSection,
    setTabSection,
  };
};

export default useSearch;
