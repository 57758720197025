import { matchPath, useHistory } from 'react-router-dom';
import { useAuthProvider } from '../../core/authContext';
import { NotifierType, ScoreTabs, SubmissionStatus } from '../../variables/types';
import { openSnackbar } from '../../components/Notifier';
import { ErrInvalidQueueNavigation, ErrQueueNotCreated, GRADES_FOR_CELEBRATION } from '../../variables/constant';
import useRole from '../../hooks/useRole';
import RoutingPaths from '../RoutingPath';

interface QueueCounter {
  graded: number
}

const useBenchmarksQueueHook = ({ readOnly = true, submissionDetails }: any) => {
  const history = useHistory();
  const { getUser } = useAuthProvider();
  let submissionIDsQueue: string[] = [];
  const { isTeacher } = useRole();

  // Returns local storage key based on current user ID
  const getQueueKey = () => {
    const user = getUser();
    return `benchmarks-submission-queue-${user?.id}`;
  };

  const getQueueCounterKey = () => {
    const user = getUser();
    return `benchmarks-queue-details-${user?.id}`;
  };

  const getBMSurveyKey = () => {
    const user = getUser();
    return `bm-gradedCount-survey-${user?.id}`;
  };

  const getQueueCounterStorage = () => {
    const value = localStorage.getItem(getQueueCounterKey());
    if (value) {
      return JSON.parse(value);
    }
    return null;
  };

  const setQueueCounter = (value: QueueCounter = { graded: 0 }) => {
    const counterQueue = getQueueCounterStorage();
    if (counterQueue && counterQueue.graded < GRADES_FOR_CELEBRATION) {
      localStorage.setItem(getQueueCounterKey(), JSON.stringify({
        graded: counterQueue.graded + value.graded,
      }));
    } else {
      localStorage.setItem(getQueueCounterKey(), JSON.stringify(value));
    }
  };

  const getGradedCount = () => (getQueueCounterStorage() ? getQueueCounterStorage().graded : 0);

  const clearQueueCounter = () => {
    localStorage.removeItem(getQueueCounterKey());
  };

  // Accepts an array of string, converts it into JSON string and stores in local storage
  const setQueue = (value: string[]) => {
    submissionIDsQueue = value;
    const counterQueue = getQueueCounterStorage();
    localStorage.setItem(getQueueKey(), JSON.stringify(value));
    if (!counterQueue) setQueueCounter();
  };

  // Reads JSON string from local storage, converts and returns as a string
  const getQueueFromStorage = (): string[] => {
    const value = localStorage.getItem(getQueueKey());
    if (value) {
      return JSON.parse(value);
    }

    return [];
  };

  if (readOnly) {
    setQueue(getQueueFromStorage());
  }

  const addBMToSurveyList = (submissionID: string) => {
    let bmVisitValue = localStorage.getItem(getBMSurveyKey());
    if (bmVisitValue) {
      const surveyArray = JSON.parse(bmVisitValue);
      if (!surveyArray.includes(submissionID)) {
        localStorage.setItem(getBMSurveyKey(),  JSON.stringify([...surveyArray, submissionID]));
      }
    }
  };


  //
  const startQueue = (submissionIDs: string[], submissionID: string) => {
    // Initialize current submission pointer
    setQueue(submissionIDs);
    if (!localStorage.getItem(getBMSurveyKey())) localStorage.setItem(getBMSurveyKey(), JSON.stringify([]));

    if (submissionIDs) {
      // Find index of current submission ID
      const currentSubmissionIndex = submissionIDs.findIndex((value: string) => value === submissionID);

      submissionIDs.splice(0, currentSubmissionIndex);
      setQueue(submissionIDs);
    }
  };

  const isFirst = (submissionID: string): boolean => submissionIDsQueue?.length > 0 && submissionIDsQueue[0] === submissionID;

  const isLast = (submissionID: string): boolean => submissionIDsQueue.length > 0 && submissionIDsQueue[submissionIDsQueue.length - 1] === submissionID;

  const getIndexBySubmissionID = (submissionID: string): number => submissionIDsQueue.findIndex(((value) => value === submissionID));

  // Accepts current submission ID and validates it be part of active queue
  const isValid = (submissionID: string): boolean => {
    const currentSubmissionIDQueueIndex = getIndexBySubmissionID(submissionID);
    return currentSubmissionIDQueueIndex >= 0;
  };

  const clearQueue = () => {
    localStorage.removeItem(getQueueKey());
    clearQueueCounter();
  };

  const flashlightGradedSubmission = () => {
    return submissionDetails?.status === SubmissionStatus.Reviewed && submissionDetails?.benchmark?.graded_by !== 'teacher';
  };

  const verifyAndAddBMToSurveyList = (submissionID: string) => {
    if (flashlightGradedSubmission()) addBMToSurveyList(submissionID);
  };

  const navigateToBenchmarkGrades = () => {
    if (isTeacher) history.push(RoutingPaths.TeacherGradeBenchMarks);
    else history.push(RoutingPaths.GradeBenchMarks);
  };

  const navigateToBenchmarkList = () => {
    if (isTeacher) history.push(RoutingPaths.TeacherBenchMarks);
    else history.push(RoutingPaths.AdminBenchmarks);
  };

  const navigationOnExitQueue = (isBMList = false) => {
    if (isBMList) navigateToBenchmarkList();
    else navigateToBenchmarkGrades();
  };

  const next = (submissionID: string): string => submissionIDsQueue[getIndexBySubmissionID(submissionID) + 1];

  const previous = (submissionID: string): string => submissionIDsQueue[getIndexBySubmissionID(submissionID) - 1];

  const showError = () => openSnackbar({ message: ErrInvalidQueueNavigation }, NotifierType.Error);

  // Accepts current submissionID and redirects to the next (whichever comes first) section or submission ID in the queue
  const handleNext = (submissionID: string, isBMlist: boolean) => {
    verifyAndAddBMToSurveyList(submissionID);
    if (isLast(submissionID)) {
      clearQueue();
      navigationOnExitQueue(isBMlist);
      return;
    }
    const nextSubmissionID = next(submissionID);
    if (!isValid(nextSubmissionID)) {
      showError();
    }

    if (getGradedCount() === GRADES_FOR_CELEBRATION) setQueueCounter();

    history.push(`/tasks/${nextSubmissionID}/benchmark/score/${ScoreTabs.Speaking}`);
  };

  const isSpeakingSection = matchPath(history.location.pathname, `${RoutingPaths.ScoreTaskBenchmarks}/${ScoreTabs.Speaking}`);

  // Accepts current submissionID and redirects to the previous submission ID in the queue
  const handlePrevious = (submissionID: string) => {
    verifyAndAddBMToSurveyList(submissionID);
    if (isSpeakingSection) {
      const previousSubmissionID = previous(submissionID);
      if (!isValid(previousSubmissionID)) {
        showError();
        return;
      }
      history.push(`/tasks/${previousSubmissionID}/benchmark/score/${ScoreTabs.Writing}`);
    } else {
      history.push(`/tasks/${submissionID}/benchmark/score/${ScoreTabs.Speaking}`);
    }
  };

  const handlePreviousSubmission = (submissionID: string) => {
    verifyAndAddBMToSurveyList(submissionID);
    const previousSubmissionID = previous(submissionID);
    if (!isValid(previousSubmissionID)) {
      showError();
      return;
    }
    history.push(`/tasks/${previousSubmissionID}/benchmark/score/${ScoreTabs.Speaking}`);
  };

  // Accepts current submissionID and redirects to the dashboard if it is not part of the current active queue
  const handleMissingQueue = (submissionID: string) => {
    if (!isValid(submissionID)) {
      openSnackbar({ message: ErrQueueNotCreated }, NotifierType.Error);
      navigationOnExitQueue();
    }
  };

  return {
    isValid,
    isFirst,
    isLast,
    handleNext,
    handlePrevious,
    isSpeakingSection,
    handleMissingQueue,
    endQueue: clearQueue,
    initializeSubmissionQueue: startQueue,
    setQueueCounter,
    getGradedCount,
    handlePreviousSubmission,
    navigationOnExitQueue,
    verifyAndAddBMToSurveyList,
  };
};

export default useBenchmarksQueueHook;
