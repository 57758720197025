import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import CustomPagination from '../../../components/CustomPagination';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { BenchmarkSuperAdminDtoSortType, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import WarningWindow from '../../../components/WarningWindow';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import { palette } from '../../../theme/palette';
import {
  DeleteNonSubmittedBenchmark,
  DeleteSubmittedBenchmark,
  READABLE_DATE_OPTIONS_YEAR,
  SubmissionTypes,
} from '../../../variables/constant';
import { StyledDeleteButton, StylesCss } from '../../Assignments/Assignments.styled';
import { CountTooltip } from '../../Assignments/AssignmentsTable/List';
import useBenchmarksList from './benchmark-list-hook';
import { toReadableFormat } from '../../../utils/dateFormat';
import { StyledTableCells } from '../../Admin/admin-tables-styled';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import useStudentSlideout from './StudentListSlideout/student-slideout-common-hook';
import FlyoverDrawer from '../../../components/FlyoverDrawer';
import FlyoverHeader from '../../../components/FlyoverHeader';
import Submitted from './StudentListSlideout/Submitted';
import NotStarted from './StudentListSlideout/NotStarted';
import InProgress from './StudentListSlideout/InProgress';
import FlyoverContainer from '../../../components/FlyoverContainer';

export const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 250px)',
});

const BenchmarkTable = () => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  const [showWarning, setShowWarning] = useState('');
  const [showPrimaryWarning, setPrimaryWarning] = useState('');
  const history = useHistory();
  const {
    benchmarksListRefetch,
    benchmarks,
    pagination,
    loading,
    totalCount,
    order,
    sort,
    onSortChange,
    onDeleteBenchmarkHandle,
    rowsPerPage,
    setRowsPerPage,
  } = useBenchmarksList();

  const {
    showSlideout,
    handleOpenSlideout,
    handleCloseSlideout,
    submissionColor,
    submissionType,
    studentCount, setStudentCount,
    entityId,
  } = useStudentSlideout();

  const handleDrawerClose = () => {
    handleCloseSlideout();
    benchmarksListRefetch();
  };

  const isTableEmpty = !loading && benchmarks?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    // @ts-ignore
    pagination.setPage(value);
  };

  const navigateToEdit = (id: string) => () => {
    history.push(`/benchmarks/${id}/edit`);
  };


  const handleSort = (column: BenchmarkSuperAdminDtoSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const getNoDataLabels = () => ({
    label: 'No Benchmarks Created Yet',
    description: 'There has yet to be a benchmark created at this time',
  });

  const handleDelete = async (id: string) => {
    onDeleteBenchmarkHandle(id);
    setShowWarning('');
    setPrimaryWarning('');
    pagination.setPage(0);
  };

  const { label, description } = getNoDataLabels();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title: string, sortEnum: BenchmarkSuperAdminDtoSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  const capitalizeLabel = (tlabel: string) => tlabel === 'multiple' ? 'Multiple' : tlabel;

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-label="Admin Benchmarks List"
            sx={{ ...((loading || isTableEmpty) && styles.tableWithNoData), ...(loading && { maxHeight: '60px' }) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCells/>
                <StyledTableCells sx={styles.width25}>
                  { sortColumn('Benchmark Title', BenchmarkSuperAdminDtoSortType.BenchmarkTitle) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width30}>
                  { sortColumn('Date', BenchmarkSuperAdminDtoSortType.StartDate) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  { sortColumn('District', BenchmarkSuperAdminDtoSortType.DistrictName) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  { sortColumn('School', BenchmarkSuperAdminDtoSortType.SchoolName) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width10}>
                  { sortColumn('Grade', BenchmarkSuperAdminDtoSortType.GradeTitle) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  Submissions
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  Actions
                </StyledTableCells>
                <StyledTableCells />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && benchmarks?.map((row) => (
                <TableRow key={row?.benchmark_id}>
                  {/*Image*/}
                  <TableCell>
                    <Box>
                      <ImageCardAssignment
                        thumbnailImageSrc={row.resource?.file.thumbnail_url || ''}
                        imageSrc={row.resource?.file?.url || ''}
                        key={row?.benchmark_id}
                      />
                    </Box>
                  </TableCell>

                  {/*Assignment Title*/}
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row.benchmark_title}
                  </TableCell>

                  {/*Date*/}
                  <TableCell
                    sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                  >
                    <TableRow>
                      {`${toReadableFormat(row.start_date, READABLE_DATE_OPTIONS_YEAR)} - `}
                    </TableRow>
                    <TableRow>
                      {`${toReadableFormat(row.close_date, READABLE_DATE_OPTIONS_YEAR)}`}
                    </TableRow>
                  </TableCell>

                  {/*District*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {capitalizeLabel(row.district_name) }
                  </TableCell>

                  {/*School*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {capitalizeLabel(row.school_name)}
                  </TableCell>

                  {/*Grade*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {capitalizeLabel(row.grade_title)}
                  </TableCell>

                  {/*Submissions*/}
                  <TableCell
                    sx={styles.countCell}
                  >
                    <Box component="div" sx={styles.flexDesign}>
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.Submitted}
                        count={row.status_aggregation?.Submitted ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.Submitted, row.status_aggregation?.Submitted ?? 0)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.InProgress}
                        count={row.status_aggregation?.InProgress ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.InProgress, row.status_aggregation?.InProgress ?? 0)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.NotStarted}
                        count={row.status_aggregation?.NotStarted ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.NotStarted, row.status_aggregation?.NotStarted ?? 0)}
                      />
                    </Box>
                  </TableCell>

                  {/*Actions*/}
                  <TableCell>
                    <Tooltip title={'Edit'}>
                      <StyledDeleteButton
                        color="primary"
                        onClick={navigateToEdit(row.benchmark_id)}
                      >
                        <PencilIcon fillColor={palette.customBlue.primaryBlue}/>
                      </StyledDeleteButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'Delete'}>
                      <StyledDeleteButton
                        color="primary"
                        type="submit"
                        onClick={() => ((row?.status_aggregation?.InProgress! !== 0
                          || row?.status_aggregation?.Submitted! !== 0)
                          ? setShowWarning(row.benchmark_id) : setPrimaryWarning(row.benchmark_id))}
                      >
                        <DeleteIcon />
                      </StyledDeleteButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                  >
                    <NoResults
                      label={label}
                      description={description}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              <Loader open={loading} />
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <CustomPagination
        totalCount={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
      <WarningWindow
        openDialog={!!showWarning}
        closeDialog={() => setShowWarning('')}
        header="Are you sure?"
        subheader={DeleteSubmittedBenchmark}
        actions={[{
          title: 'Keep Benchmark',
          event: () => (setShowWarning('')),
          classname: 'keepButton',
        }, {
          title: 'Delete Benchmark',
          event: () => handleDelete(showWarning),
          classname: 'deleteButton',
        }]}
      />
      <WarningWindow
        openDialog={!!showPrimaryWarning}
        closeDialog={() => setPrimaryWarning('')}
        header="Are you sure?"
        subheader={DeleteNonSubmittedBenchmark}
        actions={[{
          title: 'Keep Benchmark',
          event: () => (setPrimaryWarning('')),
          classname: 'keepButton',
        }, {
          title: 'Delete Benchmark',
          event: () => handleDelete(showPrimaryWarning),
          classname: 'deleteButton',
        }]}
      />
      <FlyoverDrawer open={showSlideout} size={'small'} onClose={handleCloseSlideout}>
        <FlyoverHeader
          title={submissionType}
          color={submissionColor}
          onClose={handleDrawerClose}
          subtitle={`${studentCount} ${studentCount !== 1 ? 'Students' : 'Student'}`}
        />
        <FlyoverContainer>
          {submissionType === SubmissionTypes.Submitted && <Submitted benchmarkId={entityId} setStudentCount={setStudentCount} />}
          {submissionType === SubmissionTypes.NotStarted && <NotStarted benchmarkId={entityId} setStudentCount={setStudentCount} />}
          {submissionType === SubmissionTypes.InProgress && <InProgress benchmarkId={entityId} setStudentCount={setStudentCount} />}
        </FlyoverContainer>
      </FlyoverDrawer>
    </Box>
  );
};

export default BenchmarkTable;
