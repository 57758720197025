import { useEffect, useState } from 'react';

import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import {
  BenchmarkType,
  OrderBy,
  SubmissionStatus,
  SubmissionStatusSlideoutDataInput,
  SubmissionStatusSlideoutResult,
  SubmissionStatusSlideoutSortType,
  useSubmissionStatusSlideoutQuery,
} from '../../../../../generated/graphql';
import { pieChartData } from '../../OverallPerformanceWidget/overallperformance.hooks';
import { trackEvent } from '../../../../../utils/trackevent';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,

};
const useSubmissionSlideOut = ({ data, benchmarkType }: Props) => {
  const [selectedOption, setSelectedOption] = useState('all');
  const [order, setOrder] = useState(OrderBy.Asc);
  const [sort, setSort] = useState<SubmissionStatusSlideoutSortType>(SubmissionStatusSlideoutSortType.StudentFullName);
  const handleChange = (event: any) => {
    const eventValue = event.target.value;
    trackEvent(`rb_sub_${eventValue.toLowerCase()}`, `radio_buttons_submission_status_${eventValue.toLowerCase()}_click`);
    setSelectedOption(event.target.value);
  };
  const [slideOutData, setSlideOutData] = useState<SubmissionStatusSlideoutResult[] | null>();
  const { selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest } = useTeacherBenchmarksDashboardContext();
  var queryInput: SubmissionStatusSlideoutDataInput = {
    benchmark_type: benchmarkType!,
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    submission_status: selectedOption === 'all' ? undefined : selectedOption as SubmissionStatus,
    sort_by: sort,
    order_by: order,
  };

  const { data: submissionStatusSlideOutData, loading: submissionStatusSlideOutDataLoading, refetch: refetchsubmissionStatusSlideOutData } = useSubmissionStatusSlideoutQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });
  useEffect(() => {
    refetchsubmissionStatusSlideOutData();
  }, [selectedOption, order, sort]);

  useEffect(() => {
    setSlideOutData(submissionStatusSlideOutData?.submissionStatusSlideout);
  }, [submissionStatusSlideOutData]);


  const onSortChange = (sortBy: SubmissionStatusSlideoutSortType, orderBy: OrderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: SubmissionStatusSlideoutSortType) => () => {
    if (column === queryInput?.sort_by) {
      onSortChange(column, queryInput?.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  }; 

  return {
    selectedOption,
    setSelectedOption,
    handleChange,
    order, setOrder,
    submissionStatusSlideOutData,
    submissionStatusSlideOutDataLoading,
    slideOutData,
    sort,
    handleSort,
  };
};
export default useSubmissionSlideOut;