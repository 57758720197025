import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import { StyledIconButton } from '../Assignments/Assignments.styled';
import DownloadFileIcon from '../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../components/Icons/PrintIcon';
import { palette } from '../../theme/palette';
import { useTeacherBenchmarksDashboardContext } from './teacherbenchmarks-dashboard-hook';
import { colors, extractTitle, extractWidget, getPdfTextWidth, renderChips } from '../../utils/pdf';
import { openSnackbar } from '../../components/Notifier';
import { sentryErrorLog } from '../../utils/sentry';
import { PDF_GENERATING_ERROR, TeacherBenchmarkDashBoard } from '../../variables/constant';
import { NotifierType } from '../../variables/types';
import {
  BenchmarkProgressScoreInput,
  BenchmarkProgressScoreNode,
  useBenchmarkProgressScoreQuery,
} from '../../generated/graphql';
import { ProgressScoresPdfTable } from './ProgressScores/pdf';
import { NetworkStatus } from '@apollo/client';
import useUserInfo from '../../utils/useUserInfo';
import { trackEvent } from '../../utils/trackevent';


interface ExportToPdfProps {
  tabKey?: string | number,
}

interface GeneratePdfProps {
  print: boolean,
}

const ExportToPdf = ({
  tabKey,

}: ExportToPdfProps) => {
  let pdfName = '';
  let dashboard = 'Benchmark Summary';
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [dashboardPrint, setdashboardPrint] = useState(false);
  const { areWidgetsLoading, isFilterSelected, isDataMissingInAllWidgets, setLoading, selectedGrades, selectedClasses, selectedSchools, benchmarkType, lastSnowflakeUpdateTimeData, isProgressData, isProgressScoresLoading, progressScoreDataCount, progressScoreQueryInput, legendDataOverallPerformanceWidget, setLegendDataOverallPerformanceWidget } = useTeacherBenchmarksDashboardContext();
  const queryInput: BenchmarkProgressScoreInput = { ...progressScoreQueryInput, limit: progressScoreDataCount, page: 1 };
  const {
    data: progressScoreData,
    loading: progressScoreDataLoading,
    refetch: refetchProgressScores,
    networkStatus: progressScoreNetworkStatus, 
  } = useBenchmarkProgressScoreQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: tabKey === 0,
    variables: {
      'input': {
        ...queryInput,
      },
    },
  });
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData = scoreScheme?.map((item: any) => {
    return { skillTitle: item?.title, color: item?.color_code };
  });
  // Refetch the whole Dashboard Table Data
  const fetchDashboardData = async () => {
    try {
      if (tabKey === 1) {

        await refetchProgressScores({
          input: {
            ...queryInput,
          },
        });
        setDashboardData(progressScoreData?.benchmarkProgressScore?.nodes ?? []);
      }
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfDataErr', value: `Error fetching data for PDF for District-wide Comparisons > ${tabKey}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };

  const renderFilterChips = (pdf: jsPDF, posX: number, posY: number, pdfPadding: number) => {
    let fontSize = 8;
    let height = 15;
    let defaultChipProps = {
      pdf: pdf, fontSize,
      x: posX, y: posY,
      rx: 8, ry: 8,
      height,
      backgroundColor: colors.bgGrey,
      textColor: colors.fntBlack,
    };
    const chips = [(benchmarkType as string).toUpperCase(), `Schools (${selectedSchools?.length === 0 ? 'All' : selectedSchools?.length})`, `Grades (${selectedGrades?.length === 0 ? 'All' : selectedGrades?.length})`, `Classes (${selectedClasses?.length === 0 ? 'All' : selectedClasses?.length})`];
    if (tabKey === 1) {
      chips.shift();
    }
    renderChips(pdf, posX, posY, pdfPadding, chips, defaultChipProps, fontSize);
  };


  const renderLegend = (pdf: jsPDF, posX: number, posY: number, legendData: any[]) => {
    let textWidth = 0;
    legendData.forEach(item => {
      const fontSize = 6;
      const circleRadius = 3;
      textWidth = getPdfTextWidth(item.skillTitle, pdf, fontSize);
      pdf.setFillColor(item.color);
      pdf.circle(posX + circleRadius * 2, posY - circleRadius, circleRadius, 'F');
      pdf.setFontSize(fontSize);
      pdf.setTextColor('#333');
      pdf.text(item.skillTitle, posX + circleRadius * 4, posY - 1);
      posX += textWidth + circleRadius * 6;
    });
  };

  const generatePdfCustomEvent = (print: boolean) => {
    if (tabKey === 0) {
      if (print) {
        trackEvent('tbm_bs_print', 'benchmark_summary_pdf_print');
      } else {
        trackEvent('tbm_bs_download', 'benchmark_summary_pdf_download');
      }
    } else {
      if (print) {
        trackEvent('tbm_ps_print', 'progress_score_pdf_print');
      } else {
        trackEvent('tbm_ps_download', 'progress_score_pdf_download');
      }
    }
  };

  const generatePdf = async (print: boolean = false) => {
    try {
      setLoading?.(true);
      const pdfPadding = 10;
      const pdfMargin = 20;
      const adjustHeight = 60;
      let posX = pdfMargin;
      let posY = pdfPadding + 5;
      let elHeight = 0;
      let elWidth = 0;
      const autoTableStyles = {
        margin: {
          left: pdfMargin,
          right: pdfMargin,
        },
        header: {
          fillColor: [42, 42, 139], // Set background color for the header row
          textColor: [255, 255, 255],
          fontSize: 8,
          fontStyle: 'bold',
          0: {
            halign: 'left',

          },
          7: {
            halign: 'center', // Draw a solid border on the right side of column 7
            columnWidth: 25,
          },
          13: {
            halign: 'center', // Draw a solid border on the right side of column 7
            columnWidth: 25,
          },
        },
        rows: {
          halign: 'center',
          fontSize: 8,

        },
        columns: {
          7: { columnWidth: 50 },
          11: { textColor: '#fff' },
          0: { halign: 'left' },
          3: { textColor: '#fff' },
          5: { textColor: '#fff' },
          9: { textColor: '#fff' },
          13: { columnWidth: 50 },
        },
      };
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdfDoc.internal.pageSize.getWidth();
      const pageHeight = pdfDoc.internal.pageSize.getHeight();
      let headerText = 'Benchmark Data Dashboard';
      let dashboardTitle = tabKey === 0 ? 'Benchmark Summary' : 'Progress Scores';
      let dateString1: string = '';
      [posX, posY, dateString1] = await extractTitle(pdfDoc, posX, posY, pdfPadding, pdfMargin, headerText, dashboardTitle, lastSnowflakeUpdateTimeData);
      renderFilterChips(pdfDoc, posX, posY, pdfPadding);
      if (tabKey === 0) {
        posY += 2 * pdfPadding + 5;
        posX = pdfMargin + 5;
        [posX, posY, elHeight, elWidth] = await extractWidget(pdfDoc, posX, posY, pdfPadding, TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.name, (2 * pageWidth) / 3, pdfMargin, adjustHeight, 0, ['#overall-performace-level-legend']);
        const posYforSubmissionWidget = posY;
        posX = pdfMargin + elWidth / 4 - 20; // Default positioning for legend
        posY += elHeight + 2 * pdfMargin + 5;
        renderLegend(pdfDoc, posX, posY, legendData as any[]);
        posX = elWidth + pdfMargin + 15;
        posY = posYforSubmissionWidget;
        [posX, posY, elHeight, elWidth] = await extractWidget(pdfDoc, posX, posY, pdfPadding, TeacherBenchmarkDashBoard.BenchmarkSummary.SubmissionStatusWidget.name, pageWidth / 3, pdfMargin, 42, pdfMargin);
        posX = pdfMargin + 5;
        posY += elHeight + adjustHeight;
        [posX, posY, elHeight, elWidth] = await extractWidget(pdfDoc, posX, posY, pdfPadding, TeacherBenchmarkDashBoard.BenchmarkSummary.OverallBenchmarkIndicatorWidget.name, pageWidth, pdfMargin, pdfMargin, -10);
      } else if (tabKey === 1) {
        //ProgressScore Widgets
        dashboard = 'Progress Scores';
        posY += 2 * pdfPadding + 5;
        posX = pdfMargin;
        const positionY = ProgressScoresPdfTable({ pdfDoc, posY, styles: autoTableStyles, progressScoresData: dashboardData as BenchmarkProgressScoreNode[] });
        posY = positionY + 2 * pdfPadding;
        const datalegend: any[] = [...legendData, { skillTitle: 'Incomplete / No Score', color: `${palette.customGrey.iconGrade}` }];
        renderLegend(pdfDoc, posX, posY, datalegend);
      }
      const pdfName = `${headerText}-${dashboard}-${dateString1}`;    
      pdfDoc.setProperties({
        title: pdfName,
      });
      if (print) {
        pdfDoc.autoPrint({ variant: 'non-conform' });
        window.open(pdfDoc.output('bloburl'), '_blank');
      } else {
        pdfDoc.save(pdfName);
      }
      setDashboardData([]);
      setdashboardPrint(false);
      setLoading?.(false);
    } catch (e) {
      setDashboardData([]);
      setdashboardPrint(false);
      setLoading?.(false);
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for ${dashboard}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };

  useEffect(() => {
    if (!dashboardData.length) return;
    try {
      generatePdf(
        dashboardPrint,
      );
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for District-wide Comparisons > ${tabKey}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  }, [dashboardData]);

  useEffect(() => {
    if ([progressScoreNetworkStatus].includes(NetworkStatus.refetch) && tabKey === 1) {
      setLoading?.(true);
    }
    if (tabKey === 1) {
      setLoading?.(progressScoreDataLoading);
    }
  }, [
    progressScoreDataLoading, progressScoreNetworkStatus,
  ]);



  const isDisabled = tabKey === 0 ? (areWidgetsLoading || !isFilterSelected || isDataMissingInAllWidgets) : (!isFilterSelected || !isProgressData || isProgressScoresLoading);
  return (
    <Stack direction="row" justifyContent="end" marginRight={-1} marginTop={-6} >
      <StyledIconButton
        id='download-pdf-button-benchmark-data-dashboard'
        color="primary"

        onClick={() => {
          generatePdfCustomEvent(false);
          if (tabKey === 0) { generatePdf(false); } else if (tabKey === 1) {
            setdashboardPrint(false);
            fetchDashboardData();
          }
        }}

        className="print-ignore"
        disabled={isDisabled}
      >
        <DownloadFileIcon id='download-pdf-button-benchmark-data-dashboard-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
      <StyledIconButton
        id='print-pdf-button-benchmark-data-dashboard'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(true);
          if (tabKey === 0) { generatePdf(true); } else if (tabKey === 1) {
            setdashboardPrint(true);
            fetchDashboardData();
          }
        }}

        className="print-ignore"
        disabled={isDisabled}
      >
        <PrintIcon id='print-pdf-button-benchmark-data-dashboard-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
    </Stack>

  );
};

export default ExportToPdf;