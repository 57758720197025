import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, Checkbox, Button, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormProgressBar from '../../../../components/FormProgressBar';
import Loader from '../../../../components/Loader/loader';
import WarningWindow from '../../../../components/WarningWindow';
import { openSnackbar } from '../../../../components/Notifier';
import { StyledNavigationButtonOne, StyledDangerButton } from '../../Assignments.styled';
import {
  CreateAssignmentStylesCss,
} from '../CreateAssignment.styled';
import PromptInput from './PromptInput';
import { useUploader } from '../../../../utils/multipart-uploader';
import { getToken } from '../../../../utils/auth';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../variables/types';
import { StepProp, RecordingTracker, useCreateAssignment } from '../create-assignment-hook';
import { SxProps } from '@mui/system';
import { ASSIGNMENT_TITLE_ERROR, CreatePtSteps } from '../../../../variables/constant';
import GradeCard from '../../../../components/GradeCard';
import { sentryErrorLog } from '../../../../utils/sentry';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type uploadPromptReponse = {
  response: any,
  error: boolean,
  message: string,
};

const InstructionsStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [deleteWritingPrompt, setDeleteWritingPrompt] = useState<boolean>(false);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);

  const {
    routeData,
    assignmentInput,
    backToAssignments,
    handlePrev,
    createAssignment,
    editAssignment,
    staticResourceImgSelected,
    speakingPromptFile,
    writingPromptFile,
    assignmentFetched,
    isPastAssignment,
    updateAssignmentInput,
    titleState,
    setTitleState,
    speakingPrompt,
    setSpeakingPrompt,
    writingPrompt,
    setWritingPrompt,
    recordingTracker,
    setRecordingTracker,
    setSpeakingPromptFile,
    setWritingPromptFile,
  } = useCreateAssignment();

  const {
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    clearErrors,
  } = useFormContext();

  const isCommonPrompt = watch('is_common_prompt', assignmentInput ? assignmentInput?.is_common_prompt : true);
  const { uploadAsync } = useUploader({
    // @ts-ignore : to suppress vscode lint error on meta.env tag.
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });

  const updatePromptRecording = async (file: File | null | undefined) => {
    const result: uploadPromptReponse = { response: { url: '', type: '' }, error: false, message: '' };
    try {
      if (file) {
        const resp = await uploadAsync(file);
        // eslint-disable-next-line no-param-reassign
        result.response = resp;
      }
    } catch (err) {
      sentryErrorLog(err);
      result.error = true;
      result.message = getErrorMessage(err);
    }
    return result;
  };

  const submitAssignment = async (data: any) => {
    const uploadErrors: string[] = [];
    let result: uploadPromptReponse;
    setIsFormSubmit(true);
    if (speakingPromptFile) {
      result = await updatePromptRecording(speakingPromptFile);
      if (!result.error) {
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_url = result.response.url;
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_file_type = result.response.type;
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_file_name = result.response.url.split('/').pop();
      } else {
        uploadErrors.push(result.message);
      }
    }
    if (writingPromptFile) {
      result = await updatePromptRecording(writingPromptFile);
      if (!result.error) {
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_url = result.response.url;
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_file_type = result.response.type;
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_file_name = result.response.url.split('/').pop();
      } else {
        uploadErrors.push(result.message);
      }
    }
    if (uploadErrors.length === 0) {
      if (routeData?.action === 'create') await createAssignment?.({ ...assignmentInput, ...data, ...{ title: titleState, speaking_prompt: speakingPrompt, writing_prompt: writingPrompt } });
      if (routeData?.action === 'edit') await editAssignment?.({ ...assignmentInput, ...data, ...{ title: titleState, speaking_prompt: speakingPrompt, writing_prompt: writingPrompt } });
    } else {
      uploadErrors.forEach((error) => openSnackbar({ message: error }, NotifierType.Error));
    }
    setIsFormSubmit(false);
  };

  const closeDialog = () => {
    setOpenWarningDialog(false);
  };

  const confirmSubmitAssignment = () => {
    handleSubmit(submitAssignment)();
  };

  const handleAssignmentFormSubmit = () => {
    if (routeData?.action === 'edit' && assignmentFetched?.assignment?.is_submissions_started
      && ((assignmentInput?.section_id && assignmentInput?.section_id !== assignmentFetched?.assignment?.section?.id)
        || (assignmentInput?.resource_id && assignmentInput?.resource_id !== assignmentFetched?.assignment?.resource_id)
      )
    ) {
      setOpenWarningDialog(true);
    } else {
      confirmSubmitAssignment();
    }
  };

  const isRecordingEnabled = () => recordingTracker?.some((record: RecordingTracker) => record.isRecording);

  return (
    <Box sx={styles.container}>
      <WarningWindow
        openDialog={openWarningDialog}
        header="Are you sure?"
        subheader="Some students have already finished this assignment. If
              you save these changes, ongoing and completed submissions will be lost."
        actions={[{
          title: 'Cancel Changes',
          event: closeDialog,
          buttonVariant: 'outlined',
        }, {
          title: 'Save Changes',
          event: confirmSubmitAssignment,
          buttonVariant: 'contained',
        }]}
      />
      <Loader open={isFormSubmit} />
      <Box sx={styles.formatTopNavBar}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar steps={ CreatePtSteps } progressValue={routeData?.currentStep!} />
          </Grid>
        </Grid>
      </Box>
      <form
        name="assignment-form"
        autoComplete="off"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleAssignmentFormSubmit();
        }}
      >
        <Box>
          <Grid container item xs={12}>
            <Grid item xs={5}>
              <Box sx={styles.formItemContainer}>
                <Box m={theme.spacing(2, 0, 1, 0)}>
                  <Typography variant='sFormTitle'>Title</Typography>
                </Box>
                <Controller
                  name="title"
                  defaultValue={assignmentInput?.title}
                  value={assignmentInput?.title}
                  render={({ onChange }) => (
                    <TextField
                      id="assignment-title"
                      aria-describedby="assignment-title"
                      name="title"
                      fullWidth
                      defaultValue={titleState}
                      value={titleState}
                      sx={{ ...styles.inputInput, ...((isPastAssignment) && styles.disabled) }}
                      inputProps={{ readOnly: isPastAssignment }}
                      onChange={(e) => {
                        clearErrors('title');
                        onChange(e);
                        setTitleState(e.target.value);
                      }}
                    />
                  )}
                  rules={{
                    validate: (value) => (value.trim().length > 0 || titleState.trim().length > 0) || ASSIGNMENT_TITLE_ERROR,
                  }}
                  control={control}
                />
                <Typography variant='sFormHelper'>{errors.title && errors.title.message}</Typography>
              </Box>
              <Box sx={styles.formItemContainer}>
                <Box m={theme.spacing(0, 0, 1, 0)}>
                  <Typography variant="sFormTitle">{isCommonPrompt ? 'Speaking and Writing Prompt' : 'Speaking Prompt'}</Typography>
                </Box>
                <Box m={theme.spacing(1, 0, 1, 0)}>
                  <Typography variant="sFormHelperText">
                    Students will see this prompt during the
                    {' '}
                    {isCommonPrompt ? 'Speaking and Writing Prompt' : 'Speaking Prompt'}
                    {' '}
                    portions of the assignment.
                  </Typography>
                </Box>
                <PromptInput
                  promptType="speaking"
                  isCommonPrompt={isCommonPrompt}
                  promptState={speakingPrompt}
                  setPromptState={setSpeakingPrompt}
                  isPastPT={isPastAssignment!}
                  contextInput={assignmentInput!}
                  updateContextInput={updateAssignmentInput!}
                  setSpeakingPromptFile={setSpeakingPromptFile}
                  setWritingPromptFile={setWritingPromptFile}
                  recordingTracker={recordingTracker!}
                  setRecordingTracker={setRecordingTracker!}
                />
              </Box>
              {!isCommonPrompt && (
                <>
                  <Box sx={styles.formItemContainer}>
                    <Box m={theme.spacing(2, 0, 1, 0)}>
                      <Typography variant="sFormTitle">Writing Prompt</Typography>
                    </Box>
                    <Box m={theme.spacing(1, 0, 1, 0)}>
                      <Typography variant="sFormHelperText">
                        Students will see this prompt during the Writing Prompt portions of the assignment.
                      </Typography>
                    </Box>
                    <PromptInput
                      promptType="writing"
                      isCommonPrompt={isCommonPrompt}
                      promptState={writingPrompt}
                      setPromptState={setWritingPrompt}
                      isPastPT={isPastAssignment!}
                      contextInput={assignmentInput!}
                      updateContextInput={updateAssignmentInput!}
                      setSpeakingPromptFile={setSpeakingPromptFile}
                      setWritingPromptFile={setWritingPromptFile}
                      recordingTracker={recordingTracker!}
                      setRecordingTracker={setRecordingTracker!}
                    />
                  </Box>
                </>
              )}
              <Box display="flex" mb={theme.spacing(8)} sx={{ ...styles.formItemContainer, display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Controller
                    name="is_common_prompt"
                    defaultValue={!isCommonPrompt}
                    value={!isCommonPrompt}
                    render={({ onChange, value }) => (
                      <Checkbox
                        name="is_common_prompt"
                        checked={!value}
                        onChange={(e) => {
                          onChange(!e.target.checked);
                          setDeleteWritingPrompt(false);
                          updateAssignmentInput({
                            is_common_prompt: !e.target.checked,
                          });

                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={isRecordingEnabled() || isPastAssignment}
                        sx={{ ml: -1, ...((isPastAssignment) ? styles.disabled : {}) }}
                      />
                    )}
                    rules={{
                      validate: ((value: boolean) => {
                        if (value && (assignmentInput?.writing_prompt || assignmentInput?.writing_prompt_url) && !deleteWritingPrompt) {
                          setDeleteWritingPrompt(true);
                          return 'Are you sure you do not want a separate writing prompt for your student? Your writing prompt will not be saved!';
                        }
                        return true;
                      }),
                    }}
                    control={control}
                  />
                  <Typography variant='sFormHelperText'>
                    Create a separate writing prompt.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='sFormHelper'>
                    {errors.is_common_prompt && errors.is_common_prompt.message}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={styles.formImageContainer}>
                <GradeCard
                  imageSrc={staticResourceImgSelected?.file?.url || ''}
                  thumnailImageSrc={staticResourceImgSelected?.file?.thumbnail_url || ''}
                  cardTitle={''}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.formBottomNavBar as SxProps}>
            <Box>
              <StyledNavigationButtonOne
                sx={styles.saveButton}
                variant="outlined"
                color="primary"
                onClick={() => { handlePrev(getValues()); }}
                disabled={isRecordingEnabled()}
              >
                Back
              </StyledNavigationButtonOne>
            </Box>
            <Box>
              <StyledDangerButton
                variant="outlined"
                color="primary"
                onClick={() => { backToAssignments(); }}
                sx={{ mr: 2 }}
                disabled={isRecordingEnabled()}
              >
                Cancel
              </StyledDangerButton>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isRecordingEnabled()}
              >
                {`${routeData?.action} Assignment`}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default InstructionsStep;
