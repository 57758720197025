import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../../components/ResourceDetailHeader';
import ResourceDetail from '../../Districts/District/resource-detail';
import MessageIcon from '../../../components/Icons/MessageIcon';
import useAdmin from './admin-hook';
import SchoolAdminIcon from '../../../components/Icons/SchoolAdminIcon';
import { PROVIDER_TYPE } from '../../../variables/constant';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import { Entity, ImitatationUserType } from '../../../variables/types';
import { palette } from '../../../theme/palette';
import AddNewEntity from '../../../components/AddNewEntity';
import ListWithTabs from '../../InfoTables';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import { adminsRoute } from '../../../variables/staticRoutes';

const styles =  {
  marginRight: {
    marginRight: '4px',
  },
  link: {
    fontWeight: 700,
    color: palette.customBlue.primaryBlue,
    textDecoration: 'none',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
};


const Admin = () => {
  const {
    admin,
    loading,
    isSchoolAdmin,
    isDistrictAdmin,
    type,
    editSchoolAdminPermission,
    editDistrictAdminPermission,
    imitateUserPermission,
    handleImitationLogin,
  } = useAdmin({});
  const breadcrumb = [
    adminsRoute(type),
    {
      label: `${admin?.first_name ?? ''} ${admin?.last_name ?? ''}`,
      route: `${adminsRoute(type).route}/${admin?.id}`,
    }];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />
      <ResourceDetailHeader
        title={`${admin?.first_name ?? ''} ${admin?.last_name ?? ''}`}
        addNewEntity={((isSchoolAdmin && editSchoolAdminPermission) || (isDistrictAdmin && editDistrictAdminPermission)) && admin?.districtData?.source === PROVIDER_TYPE.FLASHLIGHT ? (
          <AddNewEntity
            entity={isSchoolAdmin ? Entity.SchoolAdmin : Entity.DistrictAdmin}
            districtId={admin?.districtData?.id ?? undefined}
            stateId={admin?.districtData?.state?.id ?? undefined}
            schoolId={admin?.schoolData?.[0]?.id ?? undefined}
          />
        ) : undefined}
        primaryBtnRedirectionLink={admin?.districtData?.source !== PROVIDER_TYPE.FLASHLIGHT || (isSchoolAdmin && !editSchoolAdminPermission) || (isDistrictAdmin && !editDistrictAdminPermission) ? undefined : `/admin/create/admin/${type}/${admin?.id}`}
        PrimaryBtnIcon={admin?.districtData?.source !== PROVIDER_TYPE.FLASHLIGHT ? undefined : PencilIcon}
        primaryBtnLabel={admin?.districtData?.source !== PROVIDER_TYPE.FLASHLIGHT ? undefined : 'Edit'}
        secondaryBtnLabel={imitateUserPermission ? 'Log In As' : undefined}
        secondaryBtnOnClick={() => handleImitationLogin(
          admin?.user_id!,
          isSchoolAdmin ? ImitatationUserType.SchoolAdmin : ImitatationUserType.DistrictAdmin,
          admin?.id!,
        )}
        SecondaryBtnIcon={isSchoolAdmin ? SchoolsIcon : DistrictsIcon}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail
            label={(
              <Box display="flex">
                <Typography sx={{ ...styles.marginRight, ...styles.capitalize }}>{`${type}`}</Typography>
                <Typography sx={{ ...styles.marginRight }}>{' Administrator at '}</Typography>
                <Box>
                  {isSchoolAdmin && (
                    <Typography
                      sx={{ ...styles.link, ...styles.marginRight }}
                      component={Link}
                      to={`/schools/${admin?.schoolData?.[0]?.id ?? ''}`}
                    >
                      {admin?.schoolData?.[0]?.name ?? ''}
                    </Typography>
                  )}
                  {isDistrictAdmin && (
                    <Typography
                      sx={{ ...styles.link, ...styles.marginRight }}
                      component={Link}
                      to={`/districts/${admin?.districtData?.id}`}
                    >
                      {admin?.districtData?.name ?? ''}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            Icon={isSchoolAdmin ? SchoolsIcon : DistrictsIcon}
          />
          <ResourceDetail label={`Admin ID: ${admin?.admin_id || '-'}`} Icon={SchoolAdminIcon} />
          <ResourceDetail label={`${admin?.email || '-'}`} Icon={MessageIcon} />
        </StyledBoxWrapper>
        {(admin?.schoolData?.[0]?.id ||  admin?.districtData?.id) &&  <ListWithTabs
          sectionType={isSchoolAdmin ? 'admins/school' : 'admins/district'}
          adminID={admin.id}
          schoolID={isSchoolAdmin ? admin?.schoolData?.[0]?.id : undefined}
          districtID={ isDistrictAdmin ? admin?.districtData?.id : undefined }
        />}
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Admin);
