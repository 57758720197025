import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  SxProps,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { AdminListSort, PaginationProps } from '../../../variables/types';
import CustomPagination from '../../../components/CustomPagination';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { AdminListFragmentFragment, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { StyledTableCell, StyledTableDataCell, StyledTableDataColumn } from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import useRole from '../../../hooks/useRole';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: AdminListSort,
  order: OrderBy,
  admins?: AdminListFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminListSort) => void) | Function,
  isSchoolAdmin: boolean,
  isDistrictAdmin: boolean,
  type: string,
  marginTop?: number,
  sxProps?: SxProps,
  rowsPerPage: number,
  setRowsPerPage: Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  admins,
  handleSort,
  isSchoolAdmin,
  type,
  isDistrictAdmin,
  marginTop = 2, sxProps,
  rowsPerPage,
  setRowsPerPage,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const { isDistrictAdmin: isDistrictAdminRole } = useRole();
  const sortColumn = (title: string, sortEnum: AdminListSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
    >
      <Box>
        <StyledContainerRoot sx={ sxProps ?? {}}>
          <Table
            stickyHeader
            aria-label="admin table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  { sortColumn('Admin', AdminListSort.AdminFirstName) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Job Title', AdminListSort.JobTitle) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Admin ID', AdminListSort.AdminId) }
                </StyledTableDataCell>
                <StyledTableCell>
                  { sortColumn('District', AdminListSort.District) }
                </StyledTableCell>
                {isSchoolAdmin && (
                  <StyledTableCell>
                    { sortColumn('School', AdminListSort.School) }
                  </StyledTableCell>
                )}
                <StyledTableDataCell>
                  { sortColumn('Source', AdminListSort.Source) }
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && admins?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={`/admins/${type}/${row.id}`}
                    >
                      {`${row.first_name} ${row.last_name}`}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row.job_title || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row.admin_id || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={isDistrictAdminRole ? '/districts/my-district' : `/districts/${row.district_id}`}
                    >
                      {row.district || '-'}
                    </Typography>
                  </StyledTableDataColumn>
                  {isSchoolAdmin && (
                    <StyledTableDataColumn>
                      <Typography
                        component={Link}
                        variant='redirectionLinks'
                        to={`/schools/${row.school_id}`}
                      >
                        {row.school || '-'}
                      </Typography>
                    </StyledTableDataColumn>
                  )}
                  <StyledTableDataColumn>
                    <Typography variant='capitalized'>
                      {row?.districtData?.source}
                    </Typography>
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={isSchoolAdmin ? 6 : 5}
                    align="center"
                  >
                    <NoResults
                      label={`No ${isSchoolAdmin ? 'School' : ''}${isDistrictAdmin ? 'District' : ''} Admin`}
                      description={`There has yet to be a ${isSchoolAdmin ? 'school' : ''}${isDistrictAdmin ? 'district' : ''} admin created.`}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={isSchoolAdmin ? 6 : 5}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default React.memo(List);
