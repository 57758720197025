import {
  BenchmarkGradedBy,
  OrderBy,
  SubmissionStatus,
  TeacherBenchmarkSubmissionListSortType,
  useBenchmarkGradesCountQuery,
  useSubmissionCountQuery,
  useTeacherBenchmarkGradesListQuery,
} from '../../generated/graphql';
import useRole from '../../hooks/useRole';
import { PAGE_SIZE } from '../../variables/constant';

const useSidebarHook = () => {
  const { isTeacher } = useRole();

  const { data, loading, refetch: sidenavPTSubmissionCountRefetch } = useSubmissionCountQuery({
    variables: {
      status: [SubmissionStatus.Submitted],
    },
    skip: !isTeacher,
    // pollInterval: 120000,
  });

  const { data: teacherBenchmarkGradesData, loading: teacherBenchmarksDataLoading, refetch: teacherBenchmarksDataRefetch } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
    variables: {
      grade_statuses: [SubmissionStatus.Submitted],
      limit: PAGE_SIZE,
      page: 1,
      sort: TeacherBenchmarkSubmissionListSortType.SubmittedAt,
      order_by: OrderBy.Desc,
      graded_by: [BenchmarkGradedBy.Teacher],
    },
  });

  const { data: adminBenchmarkCount, loading: adminBenchmarkSubmissionLoading, refetch: adminBenchmarkCountRefetch } = useBenchmarkGradesCountQuery({
    fetchPolicy: 'network-only',
    skip: isTeacher,
    variables: {
      gradeStatus: [SubmissionStatus.Submitted],
    },
    // pollInterval: 120000,
  });


  return {
    loading: loading || teacherBenchmarksDataLoading || adminBenchmarkSubmissionLoading,
    submittedSubmissionCount: data?.submissionsCount ?? 0,
    sidenavPTSubmissionCountRefetch,
    sidenavBenchmarkGradeTileCount: isTeacher ? teacherBenchmarkGradesData?.teacherBenchmarkSubmissionList?.node_count ?? 0 : adminBenchmarkCount?.adminBenchmarkSubmissionsCount ?? 0,
    teacherBenchmarksDataRefetch,
    adminBenchmarkCountRefetch,

  };
};

export default useSidebarHook;
