import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { pieChartData } from '../overallperformance.hooks';
import usePerformanceLevelSlideOut from './performance-slideout-hooks';
import { BenchmarkType, ScoreDetail } from '../../../../../generated/graphql';
import useUserInfo from '../../../../../utils/useUserInfo';
import List from './List';
import { DEFAULT_SCHEME } from '../../../../../variables/constant';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,
  submissionType?: string,
};

const PerformanceSlideOut = ({ data, benchmarkType, submissionType }: Props) => {
  const { selectedOption, handleChange, order, overallPerformanceLevelSlideOutDataLoading, slideOutData, sort, handleSort } = usePerformanceLevelSlideOut({ data, benchmarkType, submissionType });
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const scoreSchemeType = user?.score_scheme?.title_key;
  const arr1 = scoreScheme.slice(0, 2);
  const arr2 = scoreScheme.slice(2, 2 + scoreScheme?.length);
  const renderScoreScheme = (scores: ScoreDetail[]) => {
    return scores.map((score: ScoreDetail) => {
      return (
        <FormControlLabel
          key={score.id}
          value={score.id}
          control={<Radio sx={{ fontSize: '14px' }} />}
          label={score.title}
          style={{ fontSize: 14, fontWeight: 400 }}
        />
      );
    });
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box width="400px" sx={{ margin: '16px', padding: '16px', overflow: 'hidden' }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleChange}
            value={selectedOption}
          >
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: scoreSchemeType === DEFAULT_SCHEME ? '1fr 0.8fr 0.8fr' : '140px 115px 100px',
            }} >
              <FormControlLabel
                value={'all'}
                control={<Radio sx={{ fontSize: '14px' }} />}
                label={'All Students'}
                style={{ fontSize: 14, fontWeight: 400 }}
              />
              { renderScoreScheme(arr1) }
            </Box>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: scoreSchemeType === DEFAULT_SCHEME ? '1fr 0.8fr 0.8fr' : '133px 160px',
            }} >
              { renderScoreScheme(arr2) }
            </Box>

          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <List
          overallPerformanceLevelSlideOutDataLoading={overallPerformanceLevelSlideOutDataLoading}
          slideOutData={slideOutData}
          order={order}
          sort={sort}
          handleSort={handleSort}
          benchmarkType={benchmarkType}
        />

      </Box>
    </Box>
  );
};
export default PerformanceSlideOut;