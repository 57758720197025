import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import LandingPage from './LandingPage';
import CreateDistrict from '../Districts/CreateDistrict';
import CreateDemo from '../Demo';
import SchoolForm from '../Schools/SchoolForm';
import TeacherForm from '../Teachers/TeacherForm';
import ClassForm from '../Classes/ClassForm';
import StudentForm from '../AdminStudents/StudentForm';
import AdminForm from '../Admin';
import useFeature from '../../hooks/useFeature';
import RoutingPaths from '../RoutingPath';

const CreateResource = () => {
  const { isEnabled: isDemoFeatureEnabled } = useFeature('DEMO_FEATURES');
  return (
    <Layout>
      <Switch>
        <Route
          path={RoutingPaths.CreateAdmin}
          component={LandingPage}
          exact
        />
        <Route
          path={[RoutingPaths.CreateDistrict, RoutingPaths.EditDistrict]}
          component={CreateDistrict}
          exact
        />
        <Route
          path={[RoutingPaths.CreateSchool, RoutingPaths.EditSchool]}
          component={SchoolForm}
          exact
        />
        <Route
          path={[RoutingPaths.CreateTeacher, RoutingPaths.EditTeacher]}
          component={TeacherForm}
          exact
        />
        <Route
          path={[RoutingPaths.CreateClass, RoutingPaths.EditClass]}
          component={ClassForm}
          exact
        />
        <Route
          path={[RoutingPaths.CreateStudent, RoutingPaths.EditStudent]}
          component={StudentForm}
          exact
        />
        <Route
          path={[RoutingPaths.AdminFormCreate, `${RoutingPaths.AdminFormCreate}/:admin`, `${RoutingPaths.AdminFormCreate}/:admin/:id`]}
          component={AdminForm}
          exact
        />
        {isDemoFeatureEnabled && (
          <Route
            path={[RoutingPaths.CreateDemo]}
            component={CreateDemo}
            exact
          />
        )}
      </Switch>
    </Layout>
  );
};

export default React.memo(CreateResource);
