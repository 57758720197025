import React from 'react';
import { Box, Grid, TextField, Checkbox, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Loader from '../../../components/Loader/loader';
import useSchoolForm from './school-form-hook';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import GradesGrid from '../GradesGrid';
import { NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import { StyledSaveButton } from '../../Districts/CreateDistrict/create-district.styled';
import { StyledDistrictsFilter, StyledStatesFilter } from './school-form-styled';
import { palette  } from '../../../theme/palette';
import { useTheme } from '@mui/material/styles';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';
import { useHistory } from 'react-router-dom';
import { generateCreateOrEditRoute, schoolsRoute } from '../../../variables/staticRoutes';

const SchoolForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createSchool,
    checked,
    handleChange,
    getValues,
    sortedPredefinedGrades,
    onDistrictChange,
    onStateChange,
    existingGrades,
    selectedStateId,
    source,
    schoolID,
  } = useSchoolForm();
  const history = useHistory();
  const breadcrumb = [generateCreateOrEditRoute('School', 'school', schoolID)];
  if (!schoolID) {
    breadcrumb.unshift(schoolsRoute);
  }
  const checkBoxLabel = "I'll be adding another school after this one";
  const schoolNameHelperText = 'Please enter the school name.';
  const schoolIDHelperText = 'Please enter a valid school ID.';
  const stateHelperText = 'Please select a state.';
  const districtHelperText = 'Please select a district.';
  const gradesHelperText = 'Please select a grade.';
  const formTitle = schoolID ? 'Update a school' : 'Add a new school';
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <SchoolsIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                marginRight: '16px',
              }}
            />
            <Typography variant="pageTitle" sx={{ mb: '8px' }}>{formTitle}</Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createSchool)}
          noValidate
        >
          <Grid
            xs={8}
            lg={6}
            container
            spacing={2}
          >
            <Grid item lg={10}>
              <Controller
                name="state_id"
                render={({ onChange }) => (
                  <StyledStatesFilter
                    value={getValues('state_id') as string}
                    onChange={(value) => {
                      onChange(value);
                      onStateChange();
                    }}
                    placeholder="Select a state"
                    idSelectOnly
                    disabled={Boolean(schoolID)}
                  />
                )}
                rules={{ required: source === PROVIDER_TYPE.FLASHLIGHT && stateHelperText  }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.state_id && errors.state_id.message}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <Controller
                name="district_id"
                render={({ onChange }) => (
                  <StyledDistrictsFilter
                    value={getValues('district_id') as string}
                    onChange={(districtID, district) => {
                      onChange(districtID);
                      onDistrictChange(district?.state_id);
                    }}
                    placeholder="Select a district"
                    idSelectOnly
                    stateIds={selectedStateId}
                    disabled={Boolean(schoolID)}
                    source={(Boolean(schoolID) && source !== PROVIDER_TYPE.FLASHLIGHT) ? [PROVIDER_TYPE.CLEVER, PROVIDER_TYPE.ROSTERSTREAM, PROVIDER_TYPE.CLASSLINK] : [PROVIDER_TYPE.FLASHLIGHT]}
                  />
                )}
                rules={{ required: districtHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.district_id && errors.district_id.message}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>School Name</Typography>
              <TextField
                id="school-name"
                aria-describedby="school-name"
                name="name"
                error={!!errors.name}
                fullWidth
                sx={{ ...(Boolean(schoolID) && source !== PROVIDER_TYPE.FLASHLIGHT && styles.disabled) }}
                helperText={errors.name && schoolNameHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
                InputProps={{ readOnly: Boolean(schoolID) && source !== PROVIDER_TYPE.FLASHLIGHT }}
              />
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>School ID</Typography>
              <TextField
                id="school-id"
                aria-describedby="school-id"
                name="school_id"
                error={!!errors.school_id}
                fullWidth
                sx={{ ...(Boolean(schoolID) && source !== PROVIDER_TYPE.FLASHLIGHT && styles.disabled) }}
                helperText={errors.school_id && schoolIDHelperText}
                InputProps={{ readOnly: Boolean(schoolID) && source !== PROVIDER_TYPE.FLASHLIGHT }}
                inputRef={register({
                  required: true,
                  pattern: NON_SPACE_REGEX,
                  validate: (value) => !!value.trim(),
                })}
              />
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>School NCES ID (optional)</Typography>
              <TextField
                id="school-nces-id"
                aria-describedby="school-nces-id"
                name="nces_id"
                inputRef={register({
                  required: false,
                })}
                fullWidth
              />
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>School MDR Number (optional)</Typography>
              <TextField
                id="mdr-number"
                aria-describedby="mdr-number"
                name="mdr_number"
                inputRef={register({
                  required: false,
                })}
                fullWidth
              />
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p'>Grade(s)</Typography>
              <Controller
                name="grades"
                render={(
                  { onChange, value },
                ) => (
                  <GradesGrid
                    onChange={onChange}
                    selected={value}
                    preDefinedGrades={sortedPredefinedGrades}
                    existingGrades={existingGrades}
                  />
                )}
                rules={{ required: true }}
                control={control}
              />
              <Typography variant='sFormHelper'>
                { errors.grades && gradesHelperText}
              </Typography>
            </Grid>
          </Grid>
          {
            !schoolID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-school-form"
                  aria-describedby="reopen-create-school-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }}>{checkBoxLabel}</Typography>
              </Box>
            )
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
            <StyledSaveButton
              variant="outlined"
              onClick={() => { history.push(schoolID ? `/schools/${schoolID}` : '/schools'); }}
            >
              Cancel
            </StyledSaveButton>
            <StyledSaveButton
              variant="contained"
              color="primary"
              type="submit"
            >
              { schoolID ? 'Update' : 'Add School' }
            </StyledSaveButton>
          </Box>
        </form>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(SchoolForm);
