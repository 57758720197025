import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import StyledTableContainer from './List.styled';
import { AssignmentSort, AssignmentTabs } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import {
  AssignmentDeleteButtonDisableTooltip,
  DeleteNonSubmittedAssignment,
  DeleteSubmittedAssignment,
  PAGE_SIZE,
  READABLE_DATE_OPTIONS_YEAR,
  SubmissionTypes,
} from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { toReadableFormat } from '../../../utils/dateFormat';
import useAssignments from './assignments-hook';
import { OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import WarningWindow from '../../../components/WarningWindow';
import useDeleteAssignment from './delete-assignment-hook';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import { StyledDeleteButton, StylesCss } from '../Assignments.styled';
import PencilIcon from '../../../components/Icons/PencilIcon';
import { palette } from '../../../theme/palette';
import { StyledTableCell, StyledTableSort } from '../../../components/Common/Common.styled';
import useStudentSlideout from '../../AdminBenchmarks/List/StudentListSlideout/student-slideout-common-hook';
import FlyoverDrawer from '../../../components/FlyoverDrawer';
import FlyoverHeader from '../../../components/FlyoverHeader';
import Submitted from './StudentListSlideout/Submitted';
import NotStarted from './StudentListSlideout/NotStarted';
import InProgress from './StudentListSlideout/InProgress';
import { useImitationProvider } from '../../../core/imitationContext';
import TypeformEmbed from '../../../components/TypeformEmbed';

interface Props {
  section?: string;
}

export const CountTooltip = (
  { tooltipTitle, count, onClick }: { tooltipTitle: NonNullable<React.ReactNode>, count: number | null, onClick?: () => void },
) => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  return (
    <Box sx={styles.countContainer} onClick={onClick}>
      <Tooltip
        title={tooltipTitle}
        sx={styles.tooltip}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <Box
          sx={{
            ...styles.count,
            ...(tooltipTitle === SubmissionTypes.Submitted && styles.submitted),
            ...(tooltipTitle === SubmissionTypes.NotStarted && styles.notStarted),
            ...(tooltipTitle === SubmissionTypes.InProgress && styles.inProgress),
          }}
        >
          {count}
        </Box>
      </Tooltip>
    </Box>
  );
};

const List = ({
  section,
}: Props) => {

  const theme = useTheme();
  const styles = StylesCss(theme);
  const [showWarning, setShowWarning] = useState('');
  const [showPrimaryWarning, setPrimaryWarning] = useState('');
  const history = useHistory();
  const {
    assignments, assignmentsRefetch,
    pagination,
    loading,
    totalCount,
    onSortChange,
    sort,
    order,
    typeformData,
    onTypeformReady,
    onTypeformSubmit,
  } = useAssignments({ section });
  const { deleting, deleteAssignment } = useDeleteAssignment();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  const {
    showSlideout,
    handleOpenSlideout,
    handleCloseSlideout,
    submissionColor,
    submissionType,
    studentCount, setStudentCount,
    entityId,
  } = useStudentSlideout();
  const handleDrawerClose = () => {
    handleCloseSlideout();
    assignmentsRefetch();
  };

  const isTableEmpty = !loading && assignments?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    // @ts-ignore
    pagination.setPage(value);
  };

  const handleSort = (column: AssignmentSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const navigateToEdit = (id: string) => () => {
    history.push(`/assignments/${id}/edit`);
  };

  const getNoDataLabels = () => {
    let label = '';
    let description = '';
    switch (section) {
      case AssignmentTabs.Past:
        label = 'No past assignments';
        description = 'There has yet to be an assignment that has passed it’s due date.';
        break;
      case AssignmentTabs.Upcoming:
        label = 'No upcoming assignments';
        description = 'There has yet to be an assignment created for an upcoming date.';
        break;
      case AssignmentTabs.Current:
      default:
        label = 'No ongoing assignments';
        description = 'There has yet to be an assignment created at this time.';
        break;
    }
    return {
      label,
      description,
    };
  };

  const handleDelete = () => {
    deleteAssignment(showWarning
      || showPrimaryWarning,
    section ?? 'current');
    setShowWarning('');
    setPrimaryWarning('');
    pagination.setPage(0);
  };

  const { label, description } = getNoDataLabels();
  const orderBy = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={loading || isTableEmpty ? styles.tableWithNoData : {}}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell sx={styles.width25}>
                  Assignment Title
                </StyledTableCell>
                <StyledTableCell sx={styles.width20}>
                  <StyledTableSort
                    direction={orderBy}
                    onClick={handleSort(AssignmentSort.StartAt)}
                    sx={styles.iconSortLabel2}
                    active={sort === AssignmentSort.StartAt}
                    IconComponent={ArrowDownIcon}
                  >
                    Date
                  </StyledTableSort>

                </StyledTableCell>
                <StyledTableCell sx={styles.width20}>
                  Assigned Class
                </StyledTableCell>
                <StyledTableCell sx={styles.width20}>
                  School
                </StyledTableCell>
                <StyledTableCell sx={styles.width10}>
                  Submissions
                </StyledTableCell>
                <StyledTableCell sx={styles.width10}>
                  Actions
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !deleting && assignments?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Box>
                      <ImageCardAssignment
                        imageSrc={row.resource?.file?.url || ''}
                        key={row.id}
                        thumbnailImageSrc={row.resource?.file?.thumbnail_url || ''}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row.title}
                  </TableCell>
                  <TableCell
                    sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                  >
                    <TableRow>
                      {`${toReadableFormat(row.start_at, READABLE_DATE_OPTIONS_YEAR)} - `}
                    </TableRow>
                    <TableRow>
                      {` ${toReadableFormat(row.close_at, READABLE_DATE_OPTIONS_YEAR)}`}
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {row.section?.name}
                  </TableCell>
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {row.section?.school?.name}
                  </TableCell>
                  <TableCell
                    sx={styles.countCell}
                  >
                    <Box component="div" sx={styles.flexDesign}>
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.Submitted}
                        count={row.status_aggregation?.Submitted ?? 0}
                        onClick={() => handleOpenSlideout(row.id, SubmissionTypes.Submitted, row.status_aggregation?.Submitted ?? 0)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.InProgress}
                        count={row.status_aggregation?.InProgress ?? 0}
                        onClick={() => handleOpenSlideout(row.id, SubmissionTypes.InProgress, row.status_aggregation?.InProgress ?? 0)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.NotStarted}
                        count={row.status_aggregation?.NotStarted ?? 0}
                        onClick={() => handleOpenSlideout(row.id, SubmissionTypes.NotStarted, row.status_aggregation?.NotStarted ?? 0)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'Edit'}>
                      <StyledDeleteButton
                        color="primary"
                        onClick={navigateToEdit(row.id)}
                      >
                        <PencilIcon fillColor={palette.customBlue.primaryBlue}/>
                      </StyledDeleteButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={row.IsSubmissionsGoalsShared ? AssignmentDeleteButtonDisableTooltip : 'Delete'}>
                      <span>
                        <StyledDeleteButton
                          color="primary"
                          type="submit"
                          disabled={row.IsSubmissionsGoalsShared ?? false}
                          onClick={() => ((row?.status_aggregation?.InProgress! !== 0
                            || row?.status_aggregation?.Submitted! !== 0)
                            ? setShowWarning(row.id) : setPrimaryWarning(row.id))}
                        >
                          <DeleteIcon />
                        </StyledDeleteButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label={label}
                      description={description}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              <Loader open={loading || deleting} />
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
      <WarningWindow
        openDialog={!!showWarning}
        closeDialog={() => setShowWarning('')}
        header="Are you sure?"
        subheader={DeleteSubmittedAssignment}
        actions={[{
          title: 'Keep Assignment',
          event: () => (setShowWarning('')),
          classname: 'keepButton',
        }, {
          title: 'Delete Assignment',
          event: handleDelete,
          classname: 'deleteButton',
        }]}
      />
      <WarningWindow
        openDialog={!!showPrimaryWarning}
        closeDialog={() => setPrimaryWarning('')}
        header="Are you sure?"
        subheader={DeleteNonSubmittedAssignment}
        actions={[{
          title: 'Keep Assignment',
          event: () => (setPrimaryWarning('')),
          classname: 'keepButton',
        }, {
          title: 'Delete Assignment',
          event: handleDelete,
          classname: 'deleteButton',
        }]}
      />
      <FlyoverDrawer open={showSlideout} size={'small'} onClose={handleCloseSlideout}>
        <FlyoverHeader
          title={submissionType}
          color={submissionColor}
          onClose={handleDrawerClose}
          subtitle={`${studentCount} ${studentCount !== 1 ? 'Students' : 'Student'}`}
        />
        {submissionType === SubmissionTypes.Submitted && <Submitted assignId={entityId} setStudentCount={setStudentCount} />}
        {submissionType === SubmissionTypes.NotStarted && <NotStarted assignId={entityId} setStudentCount={setStudentCount} />}
        {submissionType === SubmissionTypes.InProgress && <InProgress assignId={entityId} setStudentCount={setStudentCount} />}
      </FlyoverDrawer>
      {
        isNotImitated && typeformData?.show_typeform && <TypeformEmbed
          formId={typeformData?.typeform_form_id}
          typeformLayout='popup'
          onCloseHandler={onTypeformReady}
          onFormSubmittedHandler={onTypeformSubmit}
          hiddenButton={true}
          buttonText='Feedback Form'/>
      }
    </Box>
  );
};
export default List;
