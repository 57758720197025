import React from 'react';
import {
  Autocomplete,
  Checkbox,
  CheckboxProps,
  Chip,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import ClassesIcon from '../Icons/ClassesIcon';
import CheckboxCheckedIcon from '../Icons/CheckboxCheckedIcon';
import { Maybe } from 'graphql/jsutils/Maybe';
import { AdminDashboardSectionFragmentFragment, SectionCategory } from '../../generated/graphql';
import { SxProps, Theme } from '@mui/system';
import useAdminDashboardSectionFilter from './sections-multi-select-filter-hook';

interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (data: Maybe<string>[] | undefined) => void;
  stateId?: string;
  districtId?: string;
  schoolId?: string | string[];
  disabled?: boolean;
  readOnly?: boolean;
  classTypes?: SectionCategory[];
  limitTags?: number;
  textInputPlaceholder?: string;
  onSelectedClassesChange?: (selectedClasses: AdminDashboardSectionFragmentFragment[]) => void;
  sx?: SxProps<Theme> | undefined;
  teacherId?: string | string[];
  gradeId?: Maybe<string>[];
  updateLoader?: Function;
}

type ClassValue = AdminDashboardSectionFragmentFragment | null | undefined;

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  '& input.MuiInputBase-input.Mui-disabled': {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

/**
 * SectionsMultiSelectFilter: allows a user to select multiple sections from the autocomplete dropdown
 * @param Props
 * @returns MultiSelect autocomplete dropdown
 */

const SectionsMultiSelectFilter = ({
  className,
  districtId,
  schoolId,
  stateId,
  value,
  classTypes,
  onChange,
  limitTags = 3,
  textInputPlaceholder,
  onSelectedClassesChange,
  disabled = false, readOnly = false, sx,
  teacherId,
  gradeId,
  updateLoader,
}:Props) => {
  // useClassesFilter hook: to get sections based on the provided filters
  const { sections, loading } = useAdminDashboardSectionFilter({ stateId, districtId, schoolId, teacherId, classTypes });
  updateLoader?.(loading);

  const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
  const checkedIcon = <CheckboxCheckedIcon pathFill={palette.customBlue.primaryBlue} fontSize="small" />;
  const onSelectChange = (_: any, sectionsList: any[]) => {
    onChange?.(sectionsList?.map((section: any) => section?.id));
    onSelectedClassesChange?.(sectionsList);
  };

  const sectionOptions = () => {
    let sectionsFiltered = sections;
    // filter sections for selected grades.
    sectionsFiltered = (!gradeId || !gradeId.length) ? sectionsFiltered : sectionsFiltered.filter((tsection) => {
      let sectionGradeIds = tsection.section_school_predefined_grade_mappings?.map(sspgm => sspgm?.school_predefined_grade?.predefined_grade_id);
      return (sectionGradeIds?.some(sectionGrade => gradeId?.includes(sectionGrade)));
    });
    return sectionsFiltered;
  };

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      className={className}
      disableCloseOnSelect
      options={sectionOptions()}
      sx={sx}
      getOptionLabel={(option: ClassValue) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: ClassValue, selected: any) => option?.id === selected?.id}
      disabled={ disabled || loading}
      readOnly={readOnly}
      onChange={onSelectChange}
      value={sections?.filter((section) => value?.includes(section?.id))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          // selected options: viewed as removable chips in the textfield
          <Chip
            label={
              <Typography
                sx={{ fontWeight: 800, fontSize: '13px' }}
                component="span"
              >
                {option?.name}
              </Typography>
            }
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option: ClassValue, { selected }) => (
        // List Options with a checkbox divided by a divider
        <List sx={{ p: '0px' }} key={option?.id}>
          <ListItem {...props} divider>
            <Checkbox
              color={'primary' as CheckboxProps['color']}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option?.name ?? ''}
          </ListItem>
        </List>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ ...styles }}
          placeholder={params.InputProps.startAdornment ? undefined : (textInputPlaceholder ? textInputPlaceholder : 'Select class(es)')}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { height: 'auto', maxHeight: '500px', fontSize: '16px', ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' }) },
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <ClassesIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(SectionsMultiSelectFilter);
