import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClassComponent from './Class';
import ClassesTable from './ClassesTable';
import ManageClass from './ManageClass';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';

const Classes = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.ManageClass}
        component={ManageClass}
        exact
      />
      <Route
        path={RoutingPaths.Classes}
        component={ClassesTable}
        exact
      />
      <Route
        path={[`${RoutingPaths.Class}`, `${RoutingPaths.Class}/:sectionType`]}
        component={ClassComponent}
        exact
      />

    </Switch>
  </Layout>
);

export default React.memo(Classes);
