import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { SubmissionTypes } from '../../../../../variables/constant';
import { BenchmarkType } from '../../../../../generated/graphql';
import useSubmissionSlideOut from './submission-slideout-hooks';
import { pieChartData } from '../../OverallPerformanceWidget/overallperformance.hooks';
import List from './List';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,
  submissionType?: string,
};

const SubmissionSlideOut = ({ data, benchmarkType }: Props) => {
  const { selectedOption, handleChange, order, submissionStatusSlideOutDataLoading, slideOutData, sort, handleSort } = useSubmissionSlideOut({ data, benchmarkType });


  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box width="450px" sx={{ margin: '8px', padding: '16px', overflow: 'hidden' }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleChange}
            value={selectedOption}
          >
            <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 2fr 2fr' }} >
              <FormControlLabel
                value={'all'}
                control={<Radio sx={{ fontSize: '14px' }} />}
                label={'All Students'}
                style={{ fontSize: 14, fontWeight: 400 }}
              />
              {Object.keys(SubmissionTypes).map((key) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={SubmissionTypes[key]}
                  style={{ fontSize: 14, fontWeight: 400 }}
                />
              ))}
            </Box>

          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <List
          order={order}
          submissionStatusSlideOutDataLoading={submissionStatusSlideOutDataLoading}
          slideOutData={slideOutData}
          sort={sort}
          handleSort={handleSort}
          benchmarkType={benchmarkType}></List>
      </Box>
    </Box>);
};
export default SubmissionSlideOut;