import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box, Typography } from '@mui/material';
import XaxisCustomizeTick from './XaxisCustomizeTick';
import YaxisCustomizeTick from './YaxisCustomizeTick';
import { palette } from '../../theme/palette';
import { SubmissionTypes } from '../../variables/types';
import XaxisCustomizeTickWithoutIcon from './XaxisCustomizeTickWithoutIcon';

interface Props {
  submissionType: string,
  barData: any,
  xAxisKey: string,
  barKey1: string,
  barKey2: string,
  barWidth1?: number,
  barWidth2?: number,
  barHeight: number,
  barSize1: number,
  barSize2: number,
  yAxisDomain: Array<number>,
  barRadius: any,
  yAxisTicks: Array<number>,
}

const BenchmarkIndicatorPerformanceChart = ({
  submissionType,
  barData,
  xAxisKey,
  barKey1,
  barKey2,
  barWidth1 = 800,
  barWidth2 = 300,
  barHeight,
  barSize1,
  barSize2,
  yAxisDomain,
  barRadius,
  yAxisTicks,
}: Props) => {
  const indexValueForBarData: number = submissionType === SubmissionTypes.Speaking ? 4 : 2;
  const barData1 = barData.filter((data: any, index: number) => index <= indexValueForBarData);
  const barData2 = barData.filter((data: any, index: number) => index > indexValueForBarData);

  const overrideContent = ({ value, width, height, x, y, ...rest }: any) => {
    const containsDecimal = value.toString().includes('.');
    const labelColor =  (rest?.fill && rest?.fill !== palette.customYellow.main) ?
      value >= 1.5 ? palette.customWhite.main : palette.customBlack.main : palette.customBlack.main;
    return <text {...rest} x={containsDecimal ? x + width / 3 : (x + width / 2) - 2} y={y + height - 10} fill={labelColor}>{value}</text>;
  };
  
  const overrideLabelList = (key : string) => {
    return  (<LabelList
      dataKey={key}
      position="insideBottom"
      fontSize={16}
      fontWeight={800}
      content={overrideContent}
    />);
  };

  return (
    <Box sx={{ height: '300px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex',  flexDirection: 'column' }}>
            <Typography
              paddingLeft={7}
              paddingBottom={2}
              paddingTop={2}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
              }}>{submissionType}</Typography>
            <BarChart
              width={barWidth1}
              height={barHeight}
              data={barData1}
              margin={{
                top: 5,
                bottom: 40,
              }}
              barGap={0}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                scale="point"
                padding={{ left: 55, right: 60 }}
                tick={<XaxisCustomizeTick barData={barData}
                />} />
              <YAxis tickLine={false} axisLine={false}  tick={<YaxisCustomizeTick />} domain={yAxisDomain} ticks={yAxisTicks}/>
              <Bar dataKey={barKey1} fill="fill" barSize={barSize1} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey1)}
                {barData1.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.color} />
                ))}
              </Bar>
              <Bar dataKey={barKey2} fill={palette.customGrey.adminButtonBorder} barSize={barSize2} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey2)}
              </Bar>
            </BarChart>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography
              paddingBottom={2}
              paddingTop={2}
              paddingRight={(submissionType === SubmissionTypes.Speaking ? 4 : 5 )}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
                minWidth: submissionType === SubmissionTypes.Speaking ? '150px' : '160px'
              }}>Overall Score</Typography>
            <BarChart
              width={barWidth2}
              height={barHeight}
              data={barData2}
              margin={{
                top: 5,
                bottom: 40,
                left: -60,
              }}
              barGap={0}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                domain={[0, 5]}
                padding={{ left: (submissionType === SubmissionTypes.Speaking ? 120 : 460 ) }}
                tick={<XaxisCustomizeTickWithoutIcon />} />
              <YAxis tickLine={false} axisLine={false}  tick={<YaxisCustomizeTick />} domain={yAxisDomain} ticks={yAxisTicks}/>
              <Bar dataKey={barKey1} fill="fill" barSize={barSize1} isAnimationActive={false} radius={barRadius} minPointSize={4} >
                {overrideLabelList(barKey1)}
                {barData2.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.color} />
                ))}
              </Bar>
              <Bar dataKey={barKey2} fill={palette.customGrey.adminButtonBorder} barSize={barSize2} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey2)}
              </Bar>
            </BarChart>
          </Box>
        </Box>
      </ResponsiveContainer>
    </Box>
  );
};

export default BenchmarkIndicatorPerformanceChart;

